import React from 'react'
import Experts from './home/Experts'
import Footer from './Footer'
export default function FindExperts() {
  return (
    <div>
    <Experts />
    <Footer />
    </div>
  )
}
