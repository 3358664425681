import React from "react";
import { useState, useEffect } from 'react'
import http from '../../http'
import "./Modal.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
const CoursesCollageFee = ({ setFeeModalOpen, collegeId }) =>{
  const [courses, setCourses] = useState([{id:'', name:'',fee:'',coursecollege_id:''}]);
  const [selectedItems, setSelectedItems] = useState([])
  const [pageLoading, setPageLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
 
  useEffect(() => {
   fetchAssignedCourses();
  }, [])

 
 
  const fetchAssignedCourses = async () => {
    setPageLoading(true);
    await http.get('/courses-by-college/'+collegeId)
      .then(response => {
        if(response.data.length>0){
          setCourses(response.data)
        }
        setPageLoading(false);
      })
      .catch(e => {
        console.log(e);
        setPageLoading(false);
      });
  }
  const handleChange = (e,i) => {
    const {name, value}= e.target;
    const list= [...courses];
    list[i][name]= value;
    setCourses(list);
    
  }
 const formSubmitEvent = async (e) => {
    e.preventDefault();
    console.log(courses)
    try {
        setBtnLoading(true); // Set pageLoading before sending API request
        const res = await http.post('/college-courses-fee', courses)
        const response = res; // Response received
        toast.success("Course has successfully assigned !")
        setBtnLoading(false);
        setFeeModalOpen(false);
    } catch (error) {
        setBtnLoading(false); // Stop pageLoading in case of error
        toast.error("Something went wrong !")
        console.error(error);
    }
};
 return(
        <>
        
<div className="modalBackground col">
      <div className="modalContainer">
      <ToastContainer  />
      {pageLoading ? <><div className="loader"></div></> : ''}
       <div className="d-flex justify-content-between border-bottom pb-2">
          <div><h5>Assign Fee</h5></div>
          <div className="ml-auto">
            <button className="btn btn-danger btn-sm"
              onClick={() => {
                setFeeModalOpen(false);
              }}
            >
              X
            </button>
          </div>
        </div>
        <form onSubmit={formSubmitEvent}>
          <div className="mt-2 mb-2">
           <table className="table-sm">
           <tr>
             <th>Course</th>
             <th>Fee</th>
           </tr>
            <tbody>
             {courses.map((item, index) => (
              <tr key={index}>
              <td>
                <label className="form-check-label ms-2">{item.name}</label>
              </td>
              <td>
                <input type="text" name="fee" value={item.fee || ''} onChange={(e) => handleChange(e,index)} placeholder="Fee" />
              </td>
              </tr>
            ))}
           </tbody>
           </table>
            
            
           

          </div>
          <hr />
          <div className=" text-end mt-2">
          
          {
            btnLoading ? <>
            <button class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
          </> : <>
          <button type="submit" className="btn btn-primary form-submit">Submit</button>
          </>
          }
            
            <button className="btn btn-secondary  ms-2"
              onClick={() => {
                setFeeModalOpen(false);
              }}

            >
              Cancel
            </button>

          </div>
        </form>
      </div>
    </div>


   
        </>
        
    )
}

export default CoursesCollageFee;