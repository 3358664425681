import React from 'react';
import bs from './../../../images/media_coverage/bs.webp';
import danik from './../../../images/media_coverage/danik.png';
import ht from './../../../images/media_coverage/ht.png';
import inc42 from './../../../images/media_coverage/inc42.jpg';
import interp from './../../../images/media_coverage/interp.jpeg';
import yourstory from './../../../images/media_coverage/yourstory.webp';

const BrandHome = () => {

    return (
        <>
            <section className='secWrap bg-cd-light'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-4">
                            <h2 className='text-dark loanPartHead'>Media Coverage</h2>
                        </div>
                        <div className="col-lg-8">
                            <div class="slider">
                                <div class="slide-track">
                                    <div class=" loanParnerBox">
                                        <img className="logoHome" src={yourstory} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logoHome" src={interp} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logoHome" src={inc42} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logoHome" src={ht} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logoHome" src={danik} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logoHome" src={bs} alt="" />
                                    </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


        </>
    );
}

export default BrandHome;