import React from 'react';
import { useEffect } from 'react'
import Footer from '../Footer';
import WhyChoose from '../sections/WhyChoose';
import Brand from '../sections/Brand';
import HowItWork from '../sections/HowItWork';
import Testimonial from '../testimonial/Testimonial';
import FooterApply from '../sections/FooterApply';

const Loan = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section id="TopForm" className="loan-bg pb-5 pt-5 position-relative loan-apply-banner secWrap bg-cd-light">

        <div className="container">

          <div className="row">


            <div className="col-lg-6 midsec">
              <h2 className="capt-text wt-8 text-dark l_height45  mb_50 loan-heading loan-apply-new">

              We Turn your <span className="c_dark">dreams</span> <br />
               Into Reality!
              </h2>
              <p className="ft-25 text-dark wt-6">Get funded with College Dwar Education Loans</p>
              <ul className="list-unstyled mb-30 pl-0 pr_20 loan-list">
                <li>10+ Banking Partners</li>
                <li>Lower interest rates</li>
                <li>Hassle free process</li>
                <li>Quick Sanctions within 48 Hrs</li>
              </ul>
            </div>

            <div className="col-lg-6  ml-auto my-auto">

              <div className="apply-now-form shadow bg-light pl-4 pr-4 py-5" id="myForm">
                <div className='text-center'><h2><span>Lets Your Deams Take Flight</span></h2></div>
                <hr />



                <form className="contact_form_box" id="applynowform" >
                  <div className="row">

                    <div className="col-lg-6">
                      <div className="form-group text_box">
                        <input type="text" id="fname" name="name" placeholder="First Name" value="" />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group text_box">
                        <input type="text" id="lastname" name="lname" placeholder="Last Name" value="" />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group text_box">
                        <input type="tel" maxlength="10" id="number" name="contactNumber" placeholder="Contact Number" title="Only numbers allowed" pattern="[1-9]{1}[0-9]{9}" value="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group text_box">
                        <input type="email" name="emailId" id="email" placeholder="E-mail ID" value="" />
                      </div>
                    </div>


                    <div className="col-lg-6">
                      <div className="form-group text_box">

                        <select className="form-control" id="placeOfStudy" name="placeOfStudy" placeholder="Select Course">
                          <option value="">Select Course</option>
                          <option value="Tech">B.Tech</option>
                          <option value="MBA">MBA</option>
                          <option value="MCA">MCA</option>
                        </select>
                      </div>
                    </div>
                    

                    <div className="col-lg-12">
                      <input type="checkbox" id="checkbox" name="permission" value="Agreed To use data for communication" required="" checked="" />
                      <span className="text-dark"> I authorize College Dwar teams to connect with me over whatsapp and calls. </span>

                    </div>
                    <div className="col-lg-12 text-center">

                      <input type="submit" id="btn_submit" className="btn_core f_size_22" />
                  </div>
                  </div>

                </form>


              </div>

            </div>


          </div>


        </div>
      </section>
     
      <Brand />
      <WhyChoose />
      <HowItWork />
      <Testimonial />
      <FooterApply />
      <Footer />
    </>
  );
}

export default Loan;