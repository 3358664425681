import React from 'react'
import u0 from "./../../../images/uni/new/Atlas-Skilltech-University-logo.jpg";
import u1 from "./../../../images/uni/new/Fostiima.png";
import u2 from "./../../../images/uni/new/GIBS-LOGO.png";
import u3 from "./../../../images/uni/new/Jk_business_school.jpg";
import u4 from "./../../../images/uni/new/Kirloskar.png";
import u5 from "./../../../images/uni/new/empi.jpg";
import u6 from "./../../../images/uni/new/gims.png";
import u7 from "./../../../images/uni/new/gl_bajaj.png";
import u8 from "./../../../images/uni/new/ibi.png";
import u9 from "./../../../images/uni/new/iibm.avif";
import u10 from "./../../../images/uni/new/isbr.png";
import u11 from "./../../../images/uni/new/isme.png";
import u12 from "./../../../images/uni/new/isms-global.png";
import u13 from "./../../../images/uni/new/lbs_logo.png";


import { FaStar } from "react-icons/fa6";

export default function Uni() {
  return (
    <>
      <section className="features-1  secWrap">
      <div className="py-5">
        <div className="container">
            
            <div className="row g-4 justify-content-center">
            <div className='col-sm-12 mb-2 text-center'>
            <h3 className="mb-0 sec-title-txt">Our Proud Partners</h3>
               <p class="text-center mt-3 wt-6">We have partnered with 500+ Best colleges across India where you wil get best infrastructure, faculty, <br />exposure and placement opportunities</p>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u0} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u1} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u2} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u3} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u4} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u5} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u6} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u7} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u8} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u9} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u10} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u11} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u12} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u13} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u5} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u6} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u7} alt="icon1" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div className='ourproud'>
                        <div className="position-relative overflow-hidden mauto">
                            <img className="uni-img" src={u8} alt="icon1" />
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    </section>
    </>
  )
}
