import React from 'react'
import SectionCourse from './home/SectionCourse'
import SectionChoose from './home/SectionChoose'
import TakeAdmission from './home/TakeAdmission'
import Topmentors from './home/Topmentors'
import Students from './home/Students'
import Uni from './home/Uni'
import MidSec from './home/MidSec'
import FaqCard from './faq/FaqCard'
import BrandHome from './sections/BrandHome'
import TestimonialHome from './testimonial/TestimonialHome'
import SectionFeature from './home/SectionFeature'
import Footer from './Footer'
export default function Home() {
  return (
    <div>
    <SectionCourse />
    <SectionChoose />
    <TakeAdmission />
    <Topmentors />
    <MidSec />
     <BrandHome />
    <Students />
    <Uni />
    
    <TestimonialHome />
   <Footer />
    </div>
  )
}
