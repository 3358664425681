import React from 'react'

import s1 from "./../../../images/students/EMPI_Delhi.jpg";
import s2 from "./../../../images/students/GIBS_Bangalore.jpg";
import s3 from "./../../../images/students/GIMS_Noida.jpg";
import s4 from "./../../../images/students/GL_Bajaj_Noida.jpg";
import s5 from "./../../../images/students/GNOIT_Noida.jpg";
import s6 from "./../../../images/students/IIIT_Bangalore.jpg";
import s7 from "./../../../images/students/Kailash_Institute_Noida.jpg";
import s8 from "./../../../images/students/Llyod_Business_school_Noida.jpg";
import s9 from "./../../../images/students/Manipal_University_Jaipur.jpg";
import s10 from "./../../../images/students/Presidency_University_Bangalore.jpg";
import s11 from "./../../../images/students/TISS_Mumbai.jpg";
import s12 from "./../../../images/students/chandigarh_University.jpg";
import { FaStar } from "react-icons/fa6";

export default function Students() {
  return (
    <>
      <section className="features-1 bg-cd secWrap">
      <div className="py-5">
        <div className="container">
            
            <div className="row g-4 justify-content-center">
            <div className='col-sm-12 mb-2 text-center'>
               <h3 className="mb-0 text-white stu-head">10,000+</h3>
               <p class="text-center text-white mt-3 ft-25">Students ne chuna sahi course , college aur career path 
    ( Milega wahi jo hai Sahi )</p>
            </div>
                <div className="col-lg-2 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div >
                        <div className="position-relative overflow-hidden">
                            <img className="stu-img" src={s1} alt="icon1" />
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div >
                        <div className="position-relative overflow-hidden">
                            <img className="stu-img" src={s2} alt="icon1" />
                            
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div >
                        <div className="position-relative overflow-hidden">
                            <img className="stu-img" src={s3} alt="icon1" />
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div >
                        <div className="position-relative overflow-hidden">
                            <img className="stu-img" src={s4} alt="icon1" />
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div >
                        <div className="position-relative overflow-hidden">
                            <img className="stu-img" src={s5} alt="icon1" />
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div >
                        <div className="position-relative overflow-hidden">
                            <img className="stu-img" src={s6} alt="icon1" />
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div >
                        <div className="position-relative overflow-hidden">
                            <img className="stu-img" src={s7} alt="icon1" />
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div >
                        <div className="position-relative overflow-hidden">
                            <img className="stu-img" src={s8} alt="icon1" />
                          
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div >
                        <div className="position-relative overflow-hidden">
                            <img className="stu-img" src={s9} alt="icon1" />
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div >
                        <div className="position-relative overflow-hidden">
                            <img className="stu-img" src={s10} alt="icon1" />
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div >
                        <div className="position-relative overflow-hidden">
                            <img className="stu-img" src={s11} alt="icon1" />
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div >
                        <div className="position-relative overflow-hidden">
                            <img className="stu-img" src={s12} alt="icon1" />
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
    </>
  )
}
