import React from 'react'
import icon1 from "./../../../images/other/supp.jpg";
import icon2 from "./../../../images/other/placemrnt.jpg";
import icon3 from "./../../../images/other/adbenifit.jpg";

export default function TakeAdmission() {
  return (
    <>
      <section className="features-1 secWrap bg-cd-light takeadrow takoverlaySec">
        <div className="container">
          <div className="row">
            <div className='col-sm-12'>
              <div className='relative text-center'>
                <h3 className="mb-0 con-title">Take Admission through <span className="text-cd">College Dwar</span></h3>
                <p class=" mt-3 wt-6 text-white">Take admission through college Dwar Get benefits like guaranteed scholarships, placements, Gifts including Ipad, Smartwatch, Airbuds ,<br /> Amazon Prime & Netflix subscription free .</p>
                <div className='mt-1'>
                  <a href="#" class=" btn btn-md btn-primary text-white">Know More</a>
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>
    </>
  )
}
