import React, {useState, useEffect} from 'react';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import People from './Peaple';
import TestimonialDataMba from './TestimonialDataMba';

export default function TestimonialHome() {
	
	const [people, setPeople] = useState(TestimonialDataMba);
	const [index, setIndex] = useState(0);
	useEffect(() => {
		const lastIndex = people.length - 1
		  if (index < 0) {
			setIndex(lastIndex)
			}
		  if (index > lastIndex){
			setIndex(0)
			}
		 }, [index, people])
	  
	  useEffect(() => {
		let slider = setInterval(() => {
		  setIndex(index + 1)
		 }, 5000);
		  return () => clearInterval(slider)
		 }, [index])
	return (
		<>
			 <section className="section py-5 bg-cd-light">
  <div className="title">
  <h3 className="mb-0 sec-title-txt text-center">What our Students Say</h3>
               
  <div className="underline"></div>
  </div>
  <div className="section-center">
	{people.map((person, personIndex) => {
			return <People key={person.id} {...person} personIndex={personIndex} index={index} data={TestimonialDataMba} />
	})}
	<button className="prev" onClick={() => setIndex(index - 1)}>
		<FiChevronLeft />
	</button>
	<button className="next" onClick={() => setIndex(index + 1)}>
		<FiChevronRight />
	</button>
  </div>
  </section> 
		</>
	)

}