import React from 'react'
import { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import http from '../../../http'
import CoursesCollage from "../../modal/CoursesCollage";
import CoursesCollageFee from '../../modal/CoursesCollageFee';


export default function CollageList() {
  const { REACT_APP_APIURL } = process.env;
  const [collages, setCollages] = useState([])
  const [modalOpen, setModalOpen] = useState(false);
  const [feeModalOpen, setFeeModalOpen] = useState(false);
  const [collegeId, setCollegeId] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
    useEffect(() => {
        document.title = "College List";
        fetchCollages();

    }, [])
    const fetchCollages = async () => {
        try {
            setPageLoading(true); 
            const res = await http.get('/collage-list');
            const response = res; 
            setCollages(response.data)
            setPageLoading(false); 
        } catch (error) {
            setPageLoading(false);
            console.error(error);
        }
    };
    const handleDelete = (id) => {
        http.delete('/collage-delete/'+id)
            .then(response => {
                fetchCollages();
            })
            .catch(e => {
                console.log(e);
            });
    }
    const assignCourse = (id) => {
        setModalOpen(true)
        setCollegeId(id)
    }
    const assignFee = (id) => {
        setFeeModalOpen(true)
        setCollegeId(id)
    }


  return (
    <div>
{pageLoading ? <><div className="loader"></div></> : ''}
        <Link to={"/add-collage"} className="btn btn-primary btn-large" style={{float: "right"}}>Add College</Link>
        <br></br>
        <br></br>
        <h2>College List</h2>
       

      {modalOpen && <CoursesCollage setOpenModal={setModalOpen} collegeId={collegeId} />}
      {feeModalOpen && <CoursesCollageFee setFeeModalOpen={setFeeModalOpen} collegeId={collegeId} />}
        <br></br>
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>Pic</th>
                    <th>Name</th>
                    <th>City</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Courses</th>
                    <th>CourseFee</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {collages && collages.map((collage, index) => (
                    <tr key={index}>
                    <td><img className='tableImg' src={`${REACT_APP_APIURL}/storage/college/${collage.pic}`} /></td>
                        <td>{collage.name}</td>
                        <td>{collage.city?collage.city.name:''}</td>
                        <td>{collage.email}</td>
                        <td>{collage.phone}</td>
                        <td>
                        <div className='ft-12 mb-2'>
                        {
                            collage.assigned_couses.length>0?
                            collage.assigned_couses.map((course, idx) => (
                            <span>{course.name} , </span>
                            ))
                            :<div className='text-danger'>Not assigned</div>
                        }
                          
                        </div>
                        <a className='text-primary ft-12 pointer' onClick={()=>assignCourse(collage.id)}>Add Courses</a></td>
                        <td><a className='text-primary ft-12 pointer' onClick={()=>assignFee(collage.id)}>View</a></td>
                        <td>
                            <Link to={"/edit-collage/" + collage.id} className="btn btn-primary" style={{marginRight: "10px"}}>Edit</Link>
                           <button onClick={()=>{handleDelete(collage.id)}} className="btn btn-danger" >Delete</button>
                        </td>

                    </tr>
                ))}
            </tbody>
        </table>
      
    </div>
  )
}
