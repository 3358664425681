import React from 'react'

export default function BreadCrumb({pageName}) {
  return (
    <>
      <div class="home">
		<div class="breadcrumbs_container">
			<div class="container">
				<div class="row">
					<div class="col">
						<div class="breadcrumbs">
							<ul className='p-0'>
								<li>Home</li>
								<li>{pageName}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>			
	</div>
    </>
  )
}
