import React from 'react';
import { FaQuoteRight } from 'react-icons/fa6';
const People = ({id, image, name, title, quote, personIndex, index,data}) => {
  
 let position = "nextSlide";
 if(personIndex === index){
    position = 'activeSlide'
   }
 if(personIndex === index - 1 || (index === 0 && personIndex === data.length - 1)){
    position = 'lastSlide'
   }
  return(
   <article className={position} key={id}>
     <img src={image} alt={name} className="person-img" />
     <h4>{name}</h4>
     <p className="title"><b>{title}</b></p>
     <p className="text">{quote}</p>
     <FaQuoteRight className="icon" />
   </article>
        );
    }

    export default People;