import shivamImg from './../../images/testimonial/shivamjais.jpeg';
import nishikaImg from './../../images/testimonial/nishika.jpeg';
import nikhilImg from './../../images/testimonial/nikhil.jpeg';
const Data = [
	{
	  id: 1,
	  image:shivamImg,
	  name: 'Shivam Jaisawal',
	  title: 'BBA, GNIOT',
	  quote:
		'it was a great experience while dealing with College Dwar for my education loan. They provide quick response without making the Student wait. The ample amount of options available for repayment, rate of interest and other facilities is really laudable. Thank you for helping me pursue my dream!',
	},
	{
	  id: 2,
	  image:nikhilImg,
	  name: 'Nikhil Anand',
	  title: 'B.Tech, KCC',
	  quote:
		'On behalf of my parents and as a candidate I would like to take this opportunity to extend my heartfelt thanks the entire College Dwar team for arranging the disbursement of my loan at a very short notice. Our queries and qualms were considered and accordingly the product was offered which sufficed our needs. I look forward to such cooperation in the future as well.',
	},
	{
	  id: 3,
	  image:nishikaImg,
	  name: 'Nishika Prasar',
	  title: 'BPT, Kailash College',
	  quote:
		'I would like to thank College Dwar for the prompt and sincere service provided. The positive attitude displayed by the staff while problem solving is really commendable. College Dwar is highly recommended all those looking for good service and guidance while availing financial help.',
	},
	
  ];
  
  export default Data;