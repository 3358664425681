import React from 'react';
import auxilo from './../../../images/loanPartner/auxilo.webp';
import axis from './../../../images/loanPartner/axis.webp';
import hdfc from './../../../images/loanPartner/hdfc.webp';
import icici from './../../../images/loanPartner/icici.webp';
import mpower from './../../../images/loanPartner/mpower.webp';
import prodigy from './../../../images/loanPartner/prodigy.webp';
import sbi from './../../../images/loanPartner/sbi.webp';
import unionB from './../../../images/loanPartner/unionB.webp';
const Brand = () => {

    return (
        <>
            <section className='secWrap bg-cd'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-4">
                            <h2 className='text-white loanPartHead'>Our Loan Partner</h2>
                        </div>
                        <div className="col-lg-8">
                            <div class="slider">
                                <div class="slide-track">
                                    <div class=" loanParnerBox">
                                        <img className="logo" src={sbi} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logo" src={unionB} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logo" src={prodigy} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logo" src={mpower} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logo" src={icici} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logo" src={hdfc} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logo" src={axis} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logo" src={auxilo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


        </>
    );
}

export default Brand;