import React from 'react'

import sunil from "./../../../images/topMentors/sunily.jpg";
import neetu from "./../../../images/topMentors/neetu.jpg";
import tanu from "./../../../images/topMentors/tanu.jpg";
import minal from "./../../../images/topMentors/minal.jpg";

import loc from "./../../../images/other/loc.png";
import { FaMap } from "react-icons/fa6";
import { FaStar } from "react-icons/fa6";

export default function Topmentors() {
  return (
    <>
      <section className="features-1  secWrap">
      <div className="py-5">
        <div className="container">
            
            <div className="row g-4 justify-content-center">
            <div className='col-sm-12 mb-5 text-center'>
               <h3 className="mb-0 sec-title-txt">Right Guidance from Real Students</h3>
               <p class="text-center mt-3 wt-6">College Dwar has a team of 500+ real college students who are there to assist you  and 
               give you the right guidance <br /> for your successfull career ahead.</p>
            </div>
                <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                <div className="course-item bg-light">
                        <div className="position-relative overflow-hidden">
                        <div className="mentor-rate-wrap"><FaStar className='ment-rating' /> <span className='ment-rp'>4.3</span></div> 
                            <img className="img-fluid course-item-img" src={sunil} alt="icon1" />
                            
                        </div>
                        <div className="pb-2 bg-white">
                        <div className="text-center p-2 pb-0">
                            <div className="ment-name">Sunil Yadav</div>
                            <div className="mb-1 ment-degi">
                              <small> <img src={loc} className='loc' /> Mumbai </small>
                            </div>
                            <div className='ment-exp mb-2 wt-6'>TISS (Tata Institute of Social Science)</div>
                        </div>
                        <div class="text-center border-top pt-2">
                        <a href="#" className=" btn btn-md btn-primary">Consult Now</a>
                        </div>
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                <div className="course-item bg-light">
                        <div className="position-relative overflow-hidden">
                        <div className="mentor-rate-wrap"><FaStar className='ment-rating' /> <span className='ment-rp'>4.3</span></div> 
                            <img className="img-fluid course-item-img" src={neetu} alt="Nitu" />
                            
                        </div>
                        <div className="pb-2 bg-white">
                        <div className="text-center p-2 pb-0">
                            <div className="ment-name">Nitu Sharma</div>
                            <div className="mb-1 ment-degi">
                              <small> <img src={loc} className='loc' /> Bangalore </small>
                            </div>
                            <div className='ment-exp mb-2 wt-6'>GIBS (Global Institute of Business Studies)</div>
                        </div>
                        <div class="text-center border-top pt-2">
                        <a href="#" className=" btn btn-md btn-primary">Consult Now</a>
                        </div>
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                <div className="course-item bg-light">
                        <div className="position-relative overflow-hidden">
                        <div className="mentor-rate-wrap"><FaStar className='ment-rating' /> <span className='ment-rp'>4.3</span></div> 
                            <img className="img-fluid course-item-img" src={tanu} alt="icon1" />
                            
                        </div>
                        <div className="pb-2 bg-white">
                        <div className="text-center p-2 pb-0">
                            <div className="ment-name">Tanu Rani</div>
                            <div className="mb-1 ment-degi">
                              <small> <img src={loc} className='loc' /> Mumbai </small>
                            </div>
                            <div className='ment-exp mb-2 wt-6'>ITM Navi Mumbai</div>
                        </div>
                        <div class="text-center border-top pt-2">
                        <a href="#" className=" btn btn-md btn-primary">Consult Now</a>
                        </div>
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="course-item bg-light">
                        <div className="position-relative overflow-hidden">
                        <div className="mentor-rate-wrap"><FaStar className='ment-rating' /> <span className='ment-rp'>4.3</span></div> 
                            <img className="img-fluid course-item-img" src={minal} alt="icon1" />
                            
                        </div>
                        <div className="pb-2 bg-white">
                        <div className="text-center p-2 pb-0">
                            <div className="ment-name">Minal Singh</div>
                            <div className="mb-1 ment-degi">
                              <small> <img src={loc} className='loc' /> Noida </small>
                            </div>
                            <div className='ment-exp mb-2 wt-6'>GL Bajaj Institute of Management</div>
                        </div>
                        <div class="text-center border-top pt-2">
                        <a href="#" className=" btn btn-md btn-primary">Consult Now</a>
                        </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
    </>
  )
}
