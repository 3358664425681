import React from 'react'
import { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import apiUrl from '../../../CustomVar'
import http from './../../../http'

export default function MentorList() {
    const [pageLoading, setPageLoading] = useState(false);
  const [mentors, setMentors] = useState([])

    useEffect(() => {

        document.title = "Mentor List";
        fetchMentors();

    }, [])

    const fetchMentors = () => {
        setPageLoading(true); 
        http.get('/mentor-list')
            .then(response => {
                setMentors(response.data)
                setPageLoading(false); 
            })
            .catch(e => {
                console.log(e);
                setPageLoading(false); 
            });
    }

    const handleDelete = (id) => {
        http.delete('/mentor-delete/'+id)
            .then(response => {
                fetchMentors();
            })
            .catch(e => {
                console.log(e);
            });
    }


  return (
    <div>
{pageLoading ? <><div className="loader"></div></> : ''}
        <Link to={"/add-mentor"} className="btn btn-primary btn-large" style={{float: "right"}}>Add Mentor</Link>
        <br></br>
        <br></br>
        <h2>Mentor List</h2>
        <br></br>
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>College</th>
                    <th>Course</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>IdCard</th>
                    <th>Description</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {mentors && mentors.map((mentor, index) => (
                    <tr key={index}>
                        <td><img className='tableImg' src={`${apiUrl}/storage/mentor/${mentor.pic}`} /></td>
                        <td>{mentor.name}</td>
                        <td>{mentor.collage?mentor.collage.name:''}</td>
                        <td>{mentor.course?mentor.course.name:''}</td>
                        <td>{mentor.email}</td>
                        <td>{mentor.phone}</td>
                        <td><img className='tableImg' src={`${apiUrl}/storage/idcard/${mentor.idcard}`} /></td>
                        <td>-</td>
                        
                        <td>
                            <Link to={"/edit-mentor/" + mentor.id} className="btn btn-primary" style={{marginRight: "10px"}}>Edit</Link>
                           <button onClick={()=>{handleDelete(mentor.id)}} className="btn btn-danger" >Delete</button>
                        </td>

                    </tr>
                ))}
            </tbody>
        </table>
      
    </div>
  )
}
