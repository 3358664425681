import React from 'react';
import Footer from '../Footer';

const Term = () => {

  return (
    <>

      <section className="about-section secWrap">
        <div className="container">
          <div className="text-container">
            <h3>Terms And Conditions</h3>
            <p>Under no circumstances shall Corso be liable for any direct, indirect, special, incidental or consequential damages, including, but not limited to, loss of data or profit, arising out of the use, or the inability to use, the materials on this site, even if Corso or an authorized representative has been advised of the possibility of such damages. If your use of materials from this site results in the need for servicing, repair or correction of equipment or data, you assume any costs thereof should only be provided by the user of the application.</p>
          </div>
        </div>

      </section>
      <Footer />
    </>
  );
}

export default Term;