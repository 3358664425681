import React from 'react';
import ApplyForm from '../../modal/ApplyForm';
import { useState } from 'react'
const WantMore = () => {
    const [applyFormModal, setApplyFormModal] = useState(false);
    const applyNowFun=()=>{
          setApplyFormModal(true)
      }
    
    return (
        <>
        {applyFormModal && <ApplyForm setApplyFormModal={setApplyFormModal}  />}
            <section className='secWrap'>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div className="relative text-center">
                             <div className="con-title mb-4 text-dark">Want to know more or Refer your friend</div> 
                              <button className="btn btn-cd btn-lg" onClick={applyNowFun}>Apply Now</button>
                           </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default WantMore;