import React from 'react';
import Footer from '../Footer';
import HowItImg from './../../../images/How-It-Works.jpg';
const HowItWork = () => {

  return (
    <>

<section id="about-section" className="secWrap">
    <div className="container">
      <div className="row">
       
        <div className="col-md-12">
          <p className="color-base">How It Work!</p>
          <h2 className="text-dark mb-4">My Real Life is Up to You? I am a <span className="color-base">Front-end Developer</span>
          </h2>
          <p>I’m a Front-end Developer and graphic designer living in India. I spend my days with my hands in many different areas of UI development from back end programming to front end engineering.
          </p>
          <div className="row">
            <div className="col-md-12 about-bullet">
              <ul className="bullet-check">
                <li>Different of web development</li>
                <li>Gun Violence Prevention</li>
                <li>Eiusmod tempor incidid labore</li>
              </ul>
            </div>
            
          </div>

         
        </div>
        <div className="col-md-12">
     
        <img className="w-100" src={HowItImg} />
        </div>
      </div>

      
    </div>
  </section>
      <section className='secWrap'>
      <div  className="container">
        <div  className="row mt-30 mb-30">
        <div className='col-sm-12'><h2 className="mb-4">How it work</h2></div>
          <div  className="col-sm-12 col-md-3">
          
            <div  className="share-boxes">
              <img src="https://i.ibb.co/PtYrLNy/img1.png" alt="img1" border="0" />
              <p>Share with your friends</p>
            </div>
          </div>
          <div  className="col"></div>
          <div  className="col-sm-12 col-md-3">
            <div  className="share-boxes">
              <img src="https://i.ibb.co/P5TdfkT/img2.png" alt="img2" border="0" />
              <p>Give her to $5 Discount</p>
              <img src="https://i.ibb.co/Sr5F70S/dotted-arrow1.png" alt="dotted-arrow1"  className="dotted-line" />
              <img src="https://i.ibb.co/Fqs2KxB/dotted-arrow2.png" alt="dotted-arrow2"  className="dotted-line2" />
            </div>
          </div>
          <div  className="col"></div>
          <div  className="col-sm-12 col-md-3">
            <div  className="share-boxes">
              <img src="https://i.ibb.co/StC3RWk/img3.png" alt="img3" border="0" />
              <p>Get $1 for every $5</p>
            </div>
          </div>
        </div>
        </div>
       
      </section>
     
      <Footer />
    </>
  );
}

export default HowItWork;