import React from 'react';
import {useEffect } from 'react'
import Footer from '../Footer';

import aboutImg from './../../../images/about/banner-bg.png'
import onlineImg from './../../../images/about/aboutuss.JPG'
const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>

            <section className="about-section secWrap">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-7 wow fadeInUp">
                            <h2 className="f_size_40 wt-8 text-dark l_height45  mb_50 loan-heading loan-apply-new">
                                We Promote "Education with Employment" 360° Digital Solutions Provider for <span className="c_dark">Colleges.</span>
                            </h2>
                            <p className="ft-17 text-dark">
                                College Dwar
                                Eduversity offers a 360° student assistance
                                program and at zero cost from the
                                beginning of your college journey to the
                                beginning of the job. Not this much but
                                more than 100+ top B-colleges from PAN
                                India are partners of College Dwar. More than 13k
                                students have been counseled till now. More
                                than 100+ permanent employees and many
                                more temporary employees are working day
                                And night for your bright future. More than
                                500 applications are filled every year. More
                                than 100+ students are selected for the
                                scholarship every year.
                            </p>

                            <p className="mb-4">

                            </p>


                        </div>
                        <div className="col-lg-5 wow fadeInUp">
                            <div className="position-relative h-100">
                                <img className="w-100" src={aboutImg} />
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="features-1 bg-cd-light secWrap abt">
                <div className="container">
                    <div className="row g-4">
                        <div className='col-sm-12 text-center mb-4'>

                            <h3 className="mb-0 sec-title-txt text-center">WHO WE ARE</h3>
                            <p class="text-center mt-3 wt-6">College Dwar Eduversity is a unique and
                                comprehensive platform made for students
                                who are willing for higher studies but are not
                                able to choose the right career path and the
                                right college for themselves</p>
                        </div>

                    </div>
                    <div className="row">

                        <div className="col-lg-7 ml-auto aos-init aos-animate text-start align-self-center" data-aos="fade-up" data-aos-delay="100">
                            <h3 className="line-bottom mid-hed sec-title-txt mb-4">OUR VALUE</h3>

                            <div className="midsec">
                                College Dwar is working as a launching
                                pad for hundreds and thousands
                                of students who are fighting for
                                making their dreams come true.
                                As per reports, more than 55% of
                                high school students don't
                                complete their higher studies just
                                because they think they can't
                                afford the fees, hostel fee and
                                various other expenses cost too
                                much which is not affordable by
                                every willing candidate.
                                So, College Dwar also supports students
                                by providing scholarships, free
                                hostel, bags, fitness bands, and
                                various other commodities.
                                College Dwar will not help you during
                                your studies but also try to help at
                                the time of internship,
                                ambassadorships, placements.
                                College Dwar also tries to help students
                                through out their life changing
                                journey.

                            </div>
                            <h3 className="line-bottom mid-hed sec-title-txt mt-4 mb-4">OUR VISION</h3>
                            <p>
                                College Dwar is trying to make
                                education affordable and
                                accessible for every willing
                                student.
                            </p>
                            <h3 className="line-bottom mid-hed sec-title-txt mt-4 mb-4">OUR MISSION</h3>
                            <p>
                                We are on a mission to Educate
                                India and Boost up the Literacy
                                Rate in India.
                            </p>
                        </div>
                        <div className="col-lg-5 mr-auto mb-5 mb-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="0">
                            <img src={onlineImg} alt="image" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="features-1  secWrap">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-5 mr-auto mb-5 mb-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="0">
                            <img src={onlineImg} alt="image" className="img-fluid" />
                        </div>
                        <div className="col-lg-7 ml-auto aos-init aos-animate text-start align-self-center" data-aos="fade-up" data-aos-delay="100">
                            <h3 className="line-bottom mid-hed sec-title-txt">BENEFITS with College Dwar</h3>
                            <p className='mid-p mb-4'>We turn your dreams into reality!</p>
                            <div className="midsec">
                                <ul className='p-0'>
                                    <li>Get up to 50k
                                        scholarship
                                        .</li>
                                    <li>Mentorship
                                        support
                                        IIT/IIM alumni.</li>
                                    
                                    <li>Gift worth 10K.</li>
                                    <li>Internship and
                                        live project.</li>
                                    <li>Placement
                                        with minimum
                                        5LPA.</li>

                                </ul>
                            </div>
                           
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default About;