import React from 'react';
import { FaMoneyCheckDollar,FaPhoneVolume,FaNotesMedical,FaNoteSticky } from "react-icons/fa6";
const HowItWork = () => {

    return (
        <>
         <section className='secWrap bg-cd'>
<div  className="container-xxl py-5">
        <div  className="container">
            <div  className="row g-4">
            <div className='col-sm-12'><h3 className="mb-4 text-white sepLine">How It Work</h3></div>
            <div  className="col-lg-3 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-center">
                        <div  className="p-4">
                            <div className="ser-icon"><FaPhoneVolume className="text-white" /></div>
                            <h5  className="mb-3">Requirement</h5>
                            <p>Connect with our loan expert to explain your requirements</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-3 col-sm-6 wow fadeInUp" >
                    <div  className="service-item text-center">
                        <div  className="p-4">
                        <div className="ser-icon"><FaNotesMedical className="text-white" /></div>
                            <h5  className="mb-3">Documentation</h5>
                            <p>Upload your documents for verification</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-3 col-sm-6 wow fadeInUp" >
                    <div  className="service-item text-center">
                        <div  className="p-4">
                        <div className="ser-icon"><FaNoteSticky className="text-white" /></div>
                            <h5  className="mb-3">Application</h5>
                            <p>Apply to multiple loan providers as per eligibility</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-3 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-center">
                        <div  className="p-4">
                        <div className="ser-icon"><FaMoneyCheckDollar className="text-white" /></div>
                            <h5  className="mb-3">Disbursal</h5>
                            <p>Take disbursal from the bank sanctioning loan at best interest rate</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>
       
          
        </>
    );
}

export default HowItWork;