import React from 'react'
import { useState, useEffect } from 'react'
import BreadCrumb from '../BreadCrumb';
import City from '../../modal/City';
import Filter from './Filter';
import ExpertItem from './ExpertItem';


export default function Experts() {
	const [modalOpen, setModalOpen] = useState(true);
	const [experts, setExperts] = useState();
	const [courseId, setCourseId] = useState();
	const [requestedCoures, setRequestedCoures] = useState();
	const [cityId, setCityId] = useState();
	const { REACT_APP_APIURL } = process.env;
	
	const sendDataToParent = (data) => { // the callback. Use a better name
		setExperts(data)
	}; 
	const sendRequestedCourseToParent = (e) => { // the callback. Use a better name
		setRequestedCoures(e)
		console.log(e)
	};
	
	const sendParamToParent = (data) => { // the callback. Use a better name
		console.log(data)
		setCourseId(data.courseId)
		setCityId(data.cityId)
	};
	
  return (
    <>
	{modalOpen && <City setOpenModal={setModalOpen} sendDataToParent={sendDataToParent} sendParamToParent={sendParamToParent}  />}
	<BreadCrumb pageName={'Experts'} />
	<div>
			<div className="col-lg-12">
					<div className="courses_search_container">
						<Filter sendDataToParent={sendDataToParent} sendRequestedCourseToParent={sendRequestedCourseToParent}  coursePopId={courseId} cityPopId={cityId}></Filter>
					</div>
				</div>
			</div>
			
      <div className="team">
		<div className="container">
			
			<div className="row team_row">
				<div className='col-lg-12 mt-5 mb-5'>
				<span class="hide-md-down ">
                    <h1 class="tm-h1-regular tm-section-heading"><span class="tm-h1-bold tm-highlight-text agent-count-js text-primary-color">{experts?experts.length:0} Result found</span> According by you</h1>
                </span>
				<p class="tm-body tm-grey-text mb-0 tm-section-subheading">Get a free consultation and better guidance from our  Advisors</p>
				</div>
				{
					experts?experts.map((item)=>(
						<ExpertItem item={item} requestedCoures={requestedCoures} />
					))
					:
					''
				}
				
			</div>
		</div>
	</div>
</>
  )
}
