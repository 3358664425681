import React from 'react';
import ameti from './../../../images/uni/Amity_University_logo.png';
import dy from './../../../images/uni/DY_uni.jpeg';
import chit from './../../../images/uni/chit_uni.png';
import jain from './../../../images/uni/jain_uni.png';
import manipal from './../../../images/uni/manipal-uni.png';
import jindal from './../../../images/uni/op-jindal.png';
const BrandMba = () => {

    return (
        <>
            <section className='secWrap bg-cd-light'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-4">
                            <h2 className='text-dark loanPartHead'>Our Partner Universities</h2>
                        </div>
                        <div className="col-lg-8">
                            <div class="slider">
                                <div class="slide-track">
                                    <div class=" loanParnerBox">
                                        <img className="logoHome" src={ameti} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logoHome" src={jindal} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logoHome" src={jain} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logoHome" src={manipal} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logoHome" src={chit} alt="" />
                                    </div>
                                    <div class=" loanParnerBox">
                                      <img className="logoHome" src={dy} alt="" />
                                    </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


        </>
    );
}

export default BrandMba;