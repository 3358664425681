import React, { useState,useEffect } from 'react'
import rightImg from "./../../../images/clg1.jpg";
import rightImg0 from "./../../../images/slidebanner.jpg";
import rightImg1 from "./../../../images/LD1.png";
import rightImg01 from "./../../../images/about/banner-bg.png";
import { Link } from 'react-router-dom';
import http from '../../../http';
import { FaGraduationCap } from "react-icons/fa6";
export default function SectionCourse() {
  const [courseId,SetCourseId]=useState(0);
  const [courses, setCourses] = useState([]);
  const [countData, setCountData] = useState();
  useEffect(() => {
    fetchCourses();
    fetchCount();
 }, [])
  const fetchCourses = async () => {
    try {
      const res = await http.get('/courses');
      const response = res; // Response received
      setCourses(response.data)
      console.log(courses)
    } catch (error) {
     
      console.error(error);
    }
  };
  const fetchCount = async () => {
    try {
      const res = await http.get('/courses-mentor-count');
      const response = res; // Response received
      setCountData(response.data)
    } catch (error) {
      console.error(error);
    }
  };
  const handleCourse = e => {
    SetCourseId(e.target.value)
  }
  return (
    <>
    
      <div className="container-fluid hero-header bg-cd-light py-5">
        <div className="container py-1">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className='section1-top-cap'>
                The Futuristic Way To Find  <br />
                <span className='cap-primary'>Best Colleges</span>
              </div>
              <div className='relative course-pad'>
              <p className='sec1-step-count deskhowonly'></p>
              <div className='sec1-step deskhowonly'></div>
                <div className='sec1-radio row'>
                  <div className='col-md-12 wt-6 ft-18 mt-4'>
                    Select type of your courses
                  </div>
                  {
                    courses.map((item,idx)=>(
                      <div className='col-md-4 col-6' key={idx}>
                      <input type="radio" name="course" id={'radio'+idx}
                      className="radio" value={item.id} onChange={handleCourse} />
                      <label for={'radio'+idx}><FaGraduationCap className='text-cd ft-25' /> {item.name}</label>
                    </div>
                    ))
                  }
                 
                  
                 

                 
                  <div className='col-md-12 wt-6 ft-18 mt-5'>
                    How can we help you
                  </div>{
                    (courses.length>0)?
                    <div className='col-md-12 mt-3'>
                    <Link to={"/experts/mentor/" + courseId}>
                      <button className={`btn btn-primary btn-lg ${(courseId>0) ? 'animatedItem' : ''}`}>Find Mentor</button>
                    </Link>
                    <Link to={"/experts/college/" + courseId}>
                    <button className={`btn btn-secondary btn-lg ms-3 ${(courseId>0) ? 'animatedItem' : ''}`}>Find College</button>
                    </Link>
                    </div>
                    :''
                  }
                  
                  <div className='col-md-12 wt-6 ft-18 mt-3'>
                     {countData?countData.totalMentors:0}+ Mentors 
                     &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                     {countData?countData.totalColleges:0}+ Colleges 
                  </div>
                 
                  
                </div>

                
              </div>


            </div>
            {/* bannerWarp */}
            <div className="col-lg-6 fadeIn">
              <img className="img-fluid pulse infinite" src={rightImg1} alt='HomeImg' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
