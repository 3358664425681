import React from 'react'
import { useState,useEffect } from 'react'
import FaqItem from './FaqItem';
export default function FaqCard() {
	const [newFaqs, setNewFaqs] = useState([]);
	const faqs = [
		{
		  question: "How many bones does a cat have?",
		  answer: "A cat has 230 bones - 6 more than a human Outdoor cats live 5 years on average. Indoor cats live 15 years on average. Outdoor cats live 5 years on average. Indoor cats live 15 years on average. Outdoor cats live 5 years on average. Indoor cats live 15 years on average. Outdoor cats live 5 years on average. Indoor cats live 15 years on average.",
		},
		{
		  question: "How much do cats sleep?",
		  answer: "The average cat sleeps 12-16 hours per day Outdoor cats live 5 years on average. Indoor cats live 15 years on average. Outdoor cats live 5 years on average. Indoor cats live 15 years on average. Outdoor cats live 5 years on average. Indoor cats live 15 years on average.",
		},
		{
		  question: "How long do cats live",
		  answer: "Outdoor cats live 5 years on average. Indoor cats live 15 years on average. Outdoor cats live 5 years on average. Indoor cats live 15 years on average.",
		},
	  ];
	useEffect(() => {
	  setNewFaqs(
		faqs.map((item, index) =>
		  index === 0
			? { ...item, id: index, isVisible: true }
			: { ...item, id: index, isVisible: false }
		)
	  );
	}, []);
  
	const handleClick = (id) => {
	  setNewFaqs(
		newFaqs.map((item) =>
		  item.id === id
			? { ...item, isVisible: !item.isVisible }
			: { ...item, isVisible: false }
		)
	  );
	};	
	
	
  return (
    <>
	   
	   {newFaqs.map((item) => (
	  <FaqItem
		isVisible={item.isVisible}
		key={item.id}
		id={item.id}
		question={item.question}
		answer={item.answer}
		handleClick={handleClick}
	  />
	  ))}
	</>
  )
}
