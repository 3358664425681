import React from 'react';
import {useEffect } from 'react'
import Footer from '../Footer';
import contactus from "./../../../images/contactus.jpg";

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>

            <section className="about-section secWrap">
                <div id="contact" className="form-3">
                    <div className="container">
                     
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="text-container">
                                    <img src={contactus} className='img-fluid' />
                                </div>
                            </div>
                            <div className="col-lg-6">

                            <div className='col-sm-12 text-start text-dark pb-2 mb-2'><h3 className="mb-4 wt-8">Enquiry Now</h3>
                                <p>Fill the form for any enquiry!</p>
                            </div>
                                <form id="contactForm" data-toggle="validator" data-focus="false" novalidate="true">
                                    <div className="form-group mb-4">
                                        <label className="label-control" for="cname">Name</label>
                                        <input type="text" className="form-control" id="cname" required="" />

                                        <div className="help-block with-errors"></div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="label-control" for="cemail">Email</label>
                                        <input type="email" className="form-control" id="cemail" required="" />

                                        <div className="help-block with-errors"></div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="label-control" for="cmessage">Your message</label>
                                        <textarea rows={5} cols={52} className="form-control" id="cmessage" required=""></textarea>

                                        <div className="help-block with-errors"></div>
                                    </div>
                                    <div className="form-group checkbox mb-4">
                                        <input type="checkbox" id="cterms" value="Agreed-to-Terms" required="" />I have read and agree to CD's stated <a href="privacy-policy.html">Privacy Policy</a> and <a href="terms-conditions.html">Terms Conditions</a>
                                        <div className="help-block with-errors"></div>
                                    </div>
                                    <div className="form-group text-center">
                                        <button type="submit" className="btn btn-warning btn-lg">SUBMIT</button>
                                    </div>
                                    <div className="form-message">
                                        <div id="cmsgSubmit" className="h3 text-center hidden"></div>
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer />
        </>
    );
}

export default Contact;