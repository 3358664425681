import React from 'react';
import Footer from '../Footer';

import CollUniImg from './../../../images//collUniv.jpg';
import book from './../../../images/gift/bk.jpg';
import community from './../../../images/gift/community.jpg';
import network from './../../../images/gift/network.jpg';
import graduate from './../../../images/gift/graduate.jpg';
import students from './../../../images/gift/students.jpg';
import officestud from './../../../images/gift/officestud.jpg';
import buildcar from './../../../images/gift/buildcar.jpg';
import { useEffect,useState } from 'react'
import { FaSquareFacebook,FaTwitter,FaInstagram } from "react-icons/fa6";
const CollegeUniv = () => {
  const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)
 const closeMenu = () => setClick(false)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []) 
  return(
  <>
  
  <section className="loan-bg pb-5 pt-5  position-relative loan-apply-banner secWrap">

<div className="container mb-5">

  <div className="row">
    <div className="col-lg-6">
      <h2 className="f_size_40 wt-8 text-dark l_height45  mb_50 loan-heading loan-apply-new">
      Grow your College Enrollments by 130%   <br />
        <span className="c_dark">with college Dwar.</span>
      </h2>
      <p className="ft-17 text-dark">Increase your Globle Presence & the number of qualified students.</p>
      <a href='#CollegeUniForm'><button className="btn btn-cd btn-lg mt-4" onClick={closeMenu}>Work with Us</button> </a>
    </div>
    <div className="col-lg-6  ml-auto my-auto">
    <img className="w-100" src={CollUniImg} />
    </div>
  </div>
</div>
</section>
  <div className='col-sm-12'><h3 className="mb-4 text-center">100+ Colleges are doing amazing enrollments with CD</h3></div>
  <section className='secWrap bg-cd-light'>
                <div className="container">
                    <div className='row'>
                       
                        <div className="col-lg-4">
                          <div className='d-flex align-self-center'>
                            <div className='mr-2'>
                              <img src={book} />
                            </div>
                            <div className='pl-10'>
                                <p className='m-0 ft-12'>CONVERSATION</p>
                                <h6> Quality Applications</h6>
                                <p>For every hundred students, we get fifteen more enrolled than others.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className='d-flex align-self-center'>
                            <div className='mr-2'>
                              <img src={community} />
                            </div>
                            <div className='pl-10'>
                                <p className='m-0 ft-12'>DIVERSITY</p>
                                <h6> Unmatched Diversity</h6>
                                <p>College Dwar students come from across India.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className='d-flex align-self-center'>
                            <div className='mr-2'>
                              <img src={network} />
                            </div>
                            <div className='pl-10'>
                                <p className='m-0 ft-12'>PRODUCTIVITY / ROI</p>
                                <h6> Less administration. More education</h6>
                                <p>Partner Colleges spend 25% more time on things that matter.</p>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>

            </section>
  <section className='secWrap bg-cd'>
<div  className="container-xxl py-5">
        <div  className="container">
            <div  className="row g-4">
            <div className='col-sm-12 text-center text-white'><h3 className="mb-4 wt-6">Our Impact</h3>
            <p>Colleges are doing amazing enrollments with
                        CD Colleges are doing amazing enrollments with CD</p>
            </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-center pt-3">
                        <div  className="impact">
                           <div className='impactImg'><img src={graduate} /></div> 
                           <div><h5  className="mb-3">10000+</h5></div> 
                            <p>Students helped</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-center pt-3">
                        <div  className="impact">
                           <div className='impactImg'><img src={students} /></div> 
                           <div><h5  className="mb-3">100+</h5></div> 
                            <p>Partner colleges</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-center pt-3">
                        <div  className="impact">
                           <div className='impactImg'><img src={officestud} /></div> 
                           <div><h5  className="mb-3">98%</h5></div> 
                            <p>Success of Admission rate</p>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</section>
 <section className="loan-bg pb-5 pt-5  position-relative loan-apply-banner secWrap">

<div className="container mb-5">

  <div className="row">
    <div className="col-lg-6">
      <h2 className="f_size_40 wt-8 text-dark l_height45  mb_50 loan-heading loan-apply-new">
      Build brand awareness with new students  <br />
        <span className="c_dark">& families. </span>
      </h2>
      <p className="ft-17 text-dark">Bye-bye billboards and broad-reach social campaigns, and hello College Dwar! For 50 million students & families,
       College Dwar is where the search for the perfect college begins — ensure you get found with a stand-out</p>
      <h6>College Dwar Premium Profile and Sponsored Listings.</h6> 
    </div>
    <div className="col-lg-6  ml-auto my-auto">
    <img className="w-100" src={buildcar} />
    </div>
  </div>
</div>
</section>
<section className="loan-bg pb-5 pt-5  position-relative loan-apply-banner secWrap bg-cd" id="CollegeUniForm">
<div className="container mb-5">
  <div className="row">
  
      
    <div className="col-lg-6 offset-md-3 text-white">
   <div className='ft-30 wt-6'> There's a more effective way to fill your funnel and its start with College Dwar</div>
    <h6 className='ft-20 mt-4'>Book a call</h6>
      <p>to learn more about College Dwar's enrollments marketing solutions</p>
    <form className="contact_form_box mt-5" id="applynowform" >
                  <div className="row">

                    <div className="col-lg-12">
                      <div className="form-group text_box">
                        <input type="text" id="fname" name="name" placeholder="First Name" value="" />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group text_box">
                        <input type="text" id="lastname" name="lname" placeholder="Last Name" value="" />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group text_box">
                        <input type="tel" maxlength="10" id="number" name="contactNumber" placeholder="Contact Number" title="Only numbers allowed" pattern="[1-9]{1}[0-9]{9}" value="" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group text_box">
                        <input type="email" name="emailId" id="email" placeholder="E-mail ID" value="" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group text_box">
                        <input type="text" id="lastname" name="lname" placeholder="Job Title" value="" />
                      </div>
                    </div>                    
                    <div className="col-lg-12 text-center mt-4">
                      <input type="submit" id="btn_submit" className="btn btn-lg bg-white f_size_22" />
                  </div>
                  </div>

                </form>
    </div>
  </div>
</div>
</section>
  <Footer />
  </>
        );
    }

    export default CollegeUniv;