import React,{ useState } from 'react';
import ftimg from "./../../../images/bimg.jpg";

const FooterApply = () => {
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const closeMenu = () => setClick(false)
    return (
        <>
            <section className='secWrap bg-cd-light'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-3">
                        <img className="bg-rd" src={ftimg} height="150" width="250" alt="" />
                        </div>
                        <div className="col-lg-7">
                       
                        <h2 className="mt-4">Are you ready to transform your life?</h2>
                            <h4>Get a hassle-free education loan in just 3 days!</h4>
                        </div>
                        <div className="col-lg-2">
                           <a href='#TopForm'><button className="btn_core" onClick={closeMenu}>Apply Now</button> </a>
                        </div>
                    </div>
                </div>

            </section>


        </>
    );
}

export default FooterApply;