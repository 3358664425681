import React from 'react'
import { Link} from 'react-router-dom';
import d1 from "./../../../images/uni/dh.svg";
import d2 from "./../../../images/uni/dh1.webp";
import cdloan from "./../../../images/mid_sec/cdloan.png";
import mentor from "./../../../images/mid_sec/mentor1.png";
import postad from "./../../../images/mid_sec/post.png";
import refer from "./../../../images/mid_sec/refer.png";


export default function MidSec() {
  return (
    <>
    <section className="features-1 bg-cd-light secWrap">
        <div className="container">
          <div className="row">
          <div className="col-lg-6 ml-auto aos-init aos-animate text-start align-self-center" data-aos="fade-up" data-aos-delay="100">
              <h3 className="line-bottom mid-hed sec-title-txt">Post Admission Benefit</h3>
              <p className='mid-p'>Get your admission done with CD and get assured rewards.</p>
              <div className="midsec">
                <ul className='p-0'>
                  <li>Welcome kit gifts including ipad, smart watch, wildcraft bag , tshirt etc.</li>
                  <li>Guaranteed scholarships, placement, internship support.</li>
                  <li>Bootcamps, workshops, mentors from IIT/IIM.</li>
                  <li>Free Amazon Prime & Netflix Subscription.</li>
                
                </ul>
              </div>
              <p><Link className="flex-shrink-0 btn btn-sm btn-primary  tp-ex-btn mid-btn" to={"/postadmission"}>Know More</Link></p>
            </div>
            <div className="col-lg-6 mr-auto mb-5 mb-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="0">
              <img src={postad} alt="image" className="img-fluid" />
            </div>
           
          </div>
          
        </div>
      </section>
      <section className="features-1  secWrap">
        <div className="container">
         
        <div className="row">
        <div className="col-lg-6 mr-auto mb-5 mb-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="0">
              <img src={refer} alt="image" className="img-fluid" />
            </div>
            <div className="col-lg-6 ml-auto aos-init aos-animate text-start align-self-center" data-aos="fade-up" data-aos-delay="100">
              <h3 className="line-bottom mid-hed sec-title-txt">Refer & Earn</h3>
              <p className='mid-p'>Refer your friends, family and earn upto 30k per admission.</p>
              <div className="midsec">
                <ul className='p-0'>
                  <li>Refer as much as you can.</li>
                  <li>Earn upto 2-3 Lakh easily.</li>
                  <li>Get extra rewards like Iphone, Ipad, Mackbook , smartwatch etc.</li>
                </ul>
              </div>
              <p><Link className="flex-shrink-0 btn btn-sm btn-primary  tp-ex-btn mid-btn" to={"/refer"}>Know More</Link></p>
            </div>
            
          </div>
        </div>
      </section>
      <section className="features-1 bg-cd-light secWrap">
        <div className="container">
          <div className="row">
            
            <div className="col-lg-6 ml-auto aos-init aos-animate text-start align-self-center" data-aos="fade-up" data-aos-delay="100">
              <h3 className="line-bottom mid-hed sec-title-txt">Become a Mentor</h3>
              <p className='mid-p'>Make the aspirants journey easier and simpler through your experience and insights while earning handsome payouts.</p>
              <div className="midsec">
                <ul className='p-0'>
                  <li>Build your personal brand.</li>
                  <li>Give back to society.</li>
                  <li>Be a role model and inspire.</li>
                  <li>Mentor and earn.</li>
                 
                </ul>
              </div>
              <p><Link className="flex-shrink-0 btn btn-sm btn-primary  tp-ex-btn mid-btn" to={"/become/mentor"}>Know More</Link></p>
            </div>
            <div className="col-lg-6 mr-auto mb-5 mb-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="0">
              <img src={mentor} alt="image" className="img-fluid" />
            </div>
          </div>
          
        </div>
      </section>
      <section className="features-1  secWrap">
        <div className="container">
         
        <div className="row">
        <div className="col-lg-6 mr-auto mb-5 mb-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="0">
              <img src={cdloan} alt="image" className="img-fluid" />
            </div>
            <div className="col-lg-5 ml-auto aos-init aos-animate text-start align-self-center" data-aos="fade-up" data-aos-delay="100">
              <h3 className="line-bottom mid-hed sec-title-txt">College Dwar Loan</h3>
              <p className='mid-p mb-4'>We turn your dreams into reality!</p>
              <div className="midsec">
                <ul className='p-0'>
                  <li>10+ banking partners.</li>
                  <li>Lower interest rates.</li>
                  <li>Hassle free process.</li>
                  <li>Quick sanctions within 48 Hrs.</li>
                  
                </ul>
              </div>
              <p><Link className="flex-shrink-0 btn btn-sm btn-primary  tp-ex-btn mid-btn" to={"/loan"}>Know More</Link></p>
            </div>
         
          </div>
        </div>
      </section>
    </>
  )
}
