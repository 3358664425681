import React from "react";
import { useState, useEffect } from 'react'
import http from './../../http'
import "./Modal.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
const CoursesCollage = ({ setOpenModal, collegeId }) =>{
  const [users, setUsers] = useState([]);
  const [selectedItems, setSelectedItems] = useState([])
  const [pageLoading, setPageLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const notify = () => toast("Wow so easy !");
  useEffect(() => {
    fetchCourses();
   fetchAssignedCourses();
  }, [])

 
  const fetchCourses = async () => {
    try {
        setPageLoading(true); // Set pageLoading before sending API request
        const res = await http.get('/course-list');
        const response = res; // Response received
        setUsers(response.data)
        setPageLoading(false); // Stop pageLoading
    } catch (error) {
        setPageLoading(false); // Stop pageLoading in case of error
        console.error(error);
    }
};
  const fetchAssignedCourses = async () => {
    const assignedValue = [];
    await http.get('/assigned-college-course/'+collegeId)
      .then(response => {
        if(response.data.courses.length>0){
          response.data.courses.forEach((item) => {
            assignedValue.push(item.id)
        });
      }
      setSelectedItems(assignedValue)
      })
      .catch(e => {
        console.log(e);
      });
  }

	
	

	function checkboxHandler(e){
		let isSelected = e.target.checked;
		let value = parseInt(e.target.value);

		if( isSelected ){
			setSelectedItems( [...selectedItems, value ] )
		}else{
			setSelectedItems((prevData)=>{
				return prevData.filter((id)=>{
					return id!==value
				})
			})
		}
	}


	function checkAllHandler(){		
		if( users.length === selectedItems.length ){
			setSelectedItems( [] )
		}else{
			const postIds = users.map((item)=>{
				return item.id
			})
			setSelectedItems( postIds )
		}
	}
  
  const formSubmitEvent = async (e) => {
    e.preventDefault();
    try {
        setBtnLoading(true); // Set pageLoading before sending API request
        const res = await http.post('/college-courses/' + collegeId, {courses:selectedItems})
        const response = res; // Response received
        toast.success("Course has successfully assigned !")
        setBtnLoading(false);
        setOpenModal(false);
    } catch (error) {
        setBtnLoading(false); // Stop pageLoading in case of error
        toast.error("Something went wrong !")
        console.error(error);
    }
};
 return(
        <>
        
<div className="modalBackground col">
      <div className="modalContainer">
      <ToastContainer  />
      {pageLoading ? <><div className="loader"></div></> : ''}
       <div className="d-flex justify-content-between border-bottom pb-2">
          <div><h5>Assign Course</h5></div>
          <div className="ml-auto">
            <button className="btn btn-danger btn-sm"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              X
            </button>
          </div>
        </div>
        <form onSubmit={formSubmitEvent}>
          <div className="mt-2 mb-2">

            <div className="form-check mb-2">
            <label className="form-check-label ms-2  wt-6">
            <button className="btn btn-warning btn-sm" type="button" onClick={checkAllHandler} >{ users.length === selectedItems.length ? 'Uncheck All' : 'Check all' }</button>
             </label>
            </div>
            {users.map((item, index) => (
              <div className="form-check ms-4" key={index}>
              <input type="checkbox" 
                        checked={ selectedItems.includes( item.id ) } 
                        value={item.id} onChange={checkboxHandler}    />
                <label className="form-check-label ms-2">{item.name}</label>
              </div>
            ))}
            
           

          </div>
          <hr />
          <div className=" text-end mt-2">
          
          {
            btnLoading ? <>
            <button class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
          </> : <>
          <button type="submit" className="btn btn-primary form-submit">Submit</button>
          </>
          }
            
            <button className="btn btn-secondary  ms-2"
              onClick={() => {
                setOpenModal(false);
              }}

            >
              Cancel
            </button>

          </div>
        </form>
      </div>
    </div>


   
        </>
        
    )
}

export default CoursesCollage;