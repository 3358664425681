import React from 'react'
import { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import http from '../../../http'

export default function CourseList() {
  const [courses, setCourses] = useState([])
  const [pageLoading, setPageLoading] = useState(false);
    useEffect(() => {
        document.title = "Course List";
        fetchCourses();
    }, [])

    const fetchCourses = () => {
        setPageLoading(true); 
        http.get('/course-list')
            .then(response => {
                setCourses(response.data)
                setPageLoading(false);
            })
            .catch(e => {
                console.log(e);
                setPageLoading(false);
            });
    }

    const handleDelete = (id) => {
        http.delete('/course-delete/'+id)
            .then(response => {
                fetchCourses();
            })
            .catch(e => {
                console.log(e);
            });
    }


  return (
    <div>
    
        {pageLoading ? <><div className="loader"></div></> : ''}
        <Link to={"/add-course"} className="btn btn-primary btn-large" style={{float: "right"}}>Add Course</Link>
        <br></br>
        <br></br>
        <h2>Course List</h2>
        
        <br></br>
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>Name</th>
                     <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {courses && courses.map((course, index) => (
                    <tr key={index}>
                        <td>{course.name}</td>
                       <td>
                            <Link to={"/edit-course/" + course.id} className="btn btn-primary" style={{marginRight: "10px"}}>Edit</Link>
                           <button onClick={()=>{handleDelete(course.id)}} className="btn btn-danger" >Delete</button>
                        </td>

                    </tr>
                ))}
            </tbody>
        </table>
      
    </div>
  )
}
