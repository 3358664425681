import saurabh1 from './../../../images/testimonial/saurabh1.jpeg';
import monika1 from './../../../images/testimonial/monika1.jpeg';


const TestimonialDataMba = [
	{
	  id: 1,
	  image:monika1,
	  name: 'Monika Yaduvanshi',
	  title: 'Data Science, IIIT Bangalore',
	  quote:
		"In today’s competitive environment, it's always better to be up to date. However, when you are a working professional, things get tough, but not anymore, College Dwar comes to the rescue! One can attend lectures on weekends with one of the finest faculties.",
	},
	{
	  id: 2,
	  image:saurabh1,
	  name: 'Saurabh Kumar',
	  title: 'MBA, Manipal University',
	  quote:
		"I thought of having a new experience of studying along with my job. So, I decided to enrol for a course in online mode. My experience so far is great. The best part is that I can Draw my date sheet and give exams at my convenience.",
	},
	
	
  ];
  
  export default TestimonialDataMba;