import React from "react";
import { useState } from 'react'
import { useParams } from 'react-router-dom';
import http from '../../http'
import "./Modal.css";
import usr from './../../images/usr1.jpg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
const GetInTouch = ({ setGetInTouchmodal,item,requestedCoures }) => {
  const [input, setInput] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const { courseId } = useParams();
const handleChange=(e)=>{
  const { name, value } = e.target;
  setInput(values => ({ ...values, [name]: value }));
}
const submitFinder = async (e) => {
  e.preventDefault();
  if(requestedCoures===undefined){
    var reqCourse=courseId;
  }else{
    var reqCourse=requestedCoures;
  }
  
  setBtnLoading(true);
		await http.post('/request-store', { ...input,requested_course:reqCourse, requested_type: item.data_type,requested_type_id: item.id })
			.then(response => {
				setBtnLoading(false);
        toast.success("Thank you for submitting ! We will revert back you soon")
        setTimeout(function () { setGetInTouchmodal(false);}, 3000);
				})
			.catch(e => {
				setBtnLoading(false);
				console.log(e);
			});
  
 
}
  return (
    <>
<ToastContainer  />
      <div className="modalBackground col30">
        <div className="modalContainer">
        
          <form>
            <div className="mt-2 mb-2 text-center">
            <div className="popup-icon">
              <img  src={usr} alt="location"  title="getInTouch" />
             </div>
             <p className="popup-heading">Just a bit more about you!</p>
             <p className="popup-subheading">Please share your details so that our advisor can connect with you.</p>
              <div className="col-lg-12 mb-4 text-start">
              <div className="mb-4">
                  <div className="mb-1"><label>Name</label></div>
                  <input type="text" name="name" value={input.name || ''} onChange={handleChange} className="form-control" />
              </div>
              <div className="mb-4">
                  <div className="mb-1"><label>Mobile No</label></div>
                  <input type="number" name="phone" value={input.phone || ''} onChange={handleChange} className="form-control" />
              </div>
              <div className="mt-4 text-end">
              {
                btnLoading ? <>
                  <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>
                </> : <>
                  <button type="submit" className="btn btn-primary" onClick={submitFinder}>Submit</button>
                  <button type="submit" className="btn btn-light ms-3" onClick={() => {
                setGetInTouchmodal(false);
              }} >Cancel</button>
                </>
              }
              </div>
                    

              
              </div>
            </div>

            
          </form>
        </div>
      </div>



    </>

  )
}

export default GetInTouch;