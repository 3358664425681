import React from 'react';
import Slider from "react-slick";
import anmol from './../../../images/referel/anmol.jpeg'
import saurabh from './../../../images/referel/saurabh.jpeg'
import shubham from './../../../images/referel/shubham.jpeg'
import urvashi from './../../../images/referel/urvashi.jpeg'
import testiUser from './../../../images/gift/testUser.webp'
const Referal = ({ RevHead }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay:true,
    slidesToScroll: 1
  };
  return (
    <>
    <section className='secWrap mentoRev bg-cd-light'>
          <div className="container">
          <div className="mb-4">
          <div className="mb-0 sec-title-txt text-center ft-37 wt-7">Referel Earned</div>
               
          </div>
              <Slider {...settings}>
              {/* Slide 1 */}
              <div>
                  <div className='row'>
                      <div className="col-lg-3 col-sm-12 wow fadeInUp">
                        <div className="service-item text-left mentorItem">
                          <div className="text-center">
                            <div className="mentIcMid"><img className='referelImg' width={100 + 'px'} height={100 + 'px'} src={anmol} /></div>
                            <div className="mb-3 mt-2 ft-16 wt-6 ">Anmol Harsh
                              <span>BBA</span>
                            </div>
                          </div>
                         <div className="referalParaSpan">Rs 90,000</div>
                          </div>
                        </div>
                      {/* End of Col */}
                      <div className="col-lg-3 col-sm-12 wow fadeInUp">
                        <div className="service-item text-left mentorItem">
                          <div className="text-center">
                            <div className="mentIcMid"><img className='referelImg' width={100 + 'px'} height={100 + 'px'} src={saurabh} /></div>
                            <div className="mb-3 mt-2 ft-16 wt-6 ">Saurabh Kumar
                              <span>MBA</span>
                            </div>
                          </div>
                         <div className="referalParaSpan">Rs 75,000</div>
                          </div>
                        </div>
                      {/* End of Col */}
                      <div className="col-lg-3 col-sm-12 wow fadeInUp">
                        <div className="service-item text-left mentorItem">
                          <div className="text-center">
                            <div className="mentIcMid"><img className='referelImg' width={100 + 'px'} height={100 + 'px'} src={shubham} /></div>
                            <div className="mb-3 mt-2 ft-16 wt-6 ">Shubham Yaduvanshi
                              <span>PGDM</span>
                            </div>
                          </div>
                         <div className="referalParaSpan">Rs 50,000</div>
                          </div>
                        </div>
                      {/* End of Col */}
                      <div className="col-lg-3 col-sm-12 wow fadeInUp">
                        <div className="service-item text-left mentorItem">
                          <div className="text-center">
                            <div className="mentIcMid"><img className='referelImg' width={100 + 'px'} height={100 + 'px'} src={urvashi} /></div>
                            <div className="mb-3 mt-2 ft-16 wt-6 ">Urwashi khanna
                              <span>MBA</span>
                            </div>
                          </div>
                         <div className="referalParaSpan">Rs 35,000</div>
                          </div>
                        </div>
                      {/* End of Col */}
                       
                    </div>
                </div>
                {/* End of Slide 1 */}
               
              </Slider>
          </div>
      </section>
    </>
  );
}

export default Referal;