import React from 'react'
import { useState,useEffect } from 'react'
import GetInTouchPop from '../../modal/GetInTouch';
import { Link,useParams } from 'react-router-dom';
import { FaSquareFacebook,FaTwitter,FaInstagram } from "react-icons/fa6";
export default function ExpertItem({item,requestedCoures}) {
	const [getInTouchmodal, setGetInTouchmodal] = useState(false);
	const [requestedCourseId, setRequestedCourseId] = useState(false);
	const { courseId } = useParams();
	const { REACT_APP_APIURL } = process.env;
	useEffect(() => {
		userRequestedCourse();
	}, [])
	const GetInTouch=()=>{
		console.log('ff');
		setGetInTouchmodal(true)
	}
    const userRequestedCourse=()=>{
		if(requestedCoures===undefined){
			setRequestedCourseId(courseId)
		}else{
			setRequestedCourseId(requestedCoures)
		}
	}
	
	
  return (
    <>
	   
		{getInTouchmodal && <GetInTouchPop setGetInTouchmodal={setGetInTouchmodal} item={item}  requestedCoures={requestedCoures}   />}
		<div className="col-lg-3 col-md-6 team_col">
		<Link to={"/expertsInfo/" + item.data_type+'/'+item.id+'/'+requestedCourseId}>
			<div className="team_item">
				<div className="team_image">
				<img className="expertsImg" src={`${REACT_APP_APIURL}/storage/${item.data_type}/${item.pic}`} alt='team1' />
				</div>
				<div className="team_body">
					<div className="team_title">{item.name}</div>
					<div className="team_subtitle m-0 p-0">{item.email}</div>
					<div className="team_subtitle m-0 p-0">{item.college_name}</div>
					<div className="social_list m-0">
						<ul className='p-0'>
							<li><FaSquareFacebook /></li>
							<li><FaTwitter /></li>
							<li><FaInstagram /></li>
						</ul>
					</div>
					<div className='text-center mt-4'><button className='btn btn-secondary' onClick={GetInTouch}>Get In Touch</button></div>
				</div>
			</div>
			</Link>
		</div>
	</>
  )
}
