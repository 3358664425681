import sunilImg from './../../../images/testimonial/sunil_yadav_tiis1.jpeg';
import harshitaImg from './../../../images/testimonial/harshita_empi.jpeg';
import neetuImg from './../../../images/testimonial/neetu_college1.jpeg';

const TestimonialDataHome = [
	{
	  id: 1,
	  image:neetuImg,
	  name: 'Neetu Sharma',
	  title: 'GIBS, Bangalore',
	  quote:
		"College Dwar made the daunting college admissions process a breeze! Their team's expert guidance and personalized approach ensured I got accepted into my dream college. I couldn't be more grateful for their support.",
	},
	{
	  id: 2,
	  image:sunilImg,
	  name: 'Sunil Yadav',
	  title: 'TISS, Mumbai',
	  quote:
		"I can't thank College Dwar enough for their unwavering support throughout my college application journey. Their comprehensive knowledge of various colleges and the application process was invaluable. Thanks to College Dwar, I'm now a proud student at TISS, Mumbai.",
	},
	{
	  id: 3,
	  image:harshitaImg,
	  name: 'Harshita Kaushik',
	  title: 'EMPI, Delhi',
	  quote:
		"College Dwar is the reason behind my successful admission to a prestigious college. Their professional guidance and dedication helped me put together a strong application. Their team not only helped me secure admission but also scholarships. I highly recommend College Dwar to all aspiring college students.",
	},
	
  ];
  
  export default TestimonialDataHome;