import React from 'react'
import { FaChevronRight} from "react-icons/fa6";
export default function FaqItem({ question, answer, isVisible, handleClick, id }) {
	
		return (
		  <div
			className="faqs-card"
			onClick={() => handleClick(id)}
		  >
			<h3  className={isVisible ? " faq-title":' faq-title'}>
			{question}
			<span className="ml-2"><FaChevronRight  className={isVisible ? "activeFaq":'inactiveFaq'} /></span>
			</h3>
			<p  style={isVisible ? { display: 'block',animation: 'faq_fade_in_show 0.5s',background:'#f0fffd',padding:'5px' } : { display: 'none' }}>
			  {answer}
			</p>
		  </div>
		);
	  
	}
	
