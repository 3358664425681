import React from 'react';
import { FaArtstation,FaCommentsDollar,FaBrazilianRealSign,FaChartBar,FaCashRegister,FaCheckToSlot } from "react-icons/fa6";
import onlineImg from './../../../images/onlinemba.jpg'
const WhyChoose = () => {

  return (
    <>
    <section className='secWrap bg-cd-light'>
<div  className="container-xxl py-5">
        <div  className="container">
            <div  className="row g-4">
            <h3 className="mb-0 sec-title-txt text-center">Why Choose Us</h3>
               <p class="text-center mt-3 wt-6">College Dwar has a team of 2500+ experts who are there to assist you and give you the right guidance
              <br /> for your successful career ahead.</p>
                <div  className="col-lg-3 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-center">
                        <div  className="p-4">
                            <div className="ser-icon"><FaCommentsDollar className="text-white" /></div>
                            <h5  className="mb-3">COMPREHENSIVE</h5>
                            <p>Avail up to 100% finance for your tuition fee & living costs</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-3 col-sm-6 wow fadeInUp" >
                    <div  className="service-item text-center">
                        <div  className="p-4">
                        <div className="ser-icon"><FaArtstation className="text-white" /></div>
                            <h5  className="mb-3">ENTIRELY DIGITAL</h5>
                            <p>Cut the queue with our truly online, end-to-end loan offering</p>
                        </div>
                    </div>
                </div>
                
                <div  className="col-lg-3 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-center">
                        <div  className="p-4">
                        <div className="ser-icon"><FaBrazilianRealSign className="text-white" /></div>
                            <h5  className="mb-3">FLEXIBLE REPAYMENT</h5>
                            <p>Get easy EMIs with flexible repayment structures</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-3 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-center">
                        <div  className="p-4">
                        <div className="ser-icon"><FaChartBar className="text-white" /></div>
                            <h5  className="mb-3">LOWEST INTEREST RATES</h5>
                            <p>Access global education opportunities with the lowest loan interest rates</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-3 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-center">
                        <div  className="p-4">
                        <div className="ser-icon"><FaCashRegister className="text-white" /></div>
                            <h5  className="mb-3">EASY APPLICATION</h5>
                            <p>File a common application for all lenders & we take care of the rest</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-3 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-center">
                        <div  className="p-4">
                        <div className="ser-icon"><FaCheckToSlot className="text-white" /></div>
                            <h5  className="mb-3">FAST PROCESSING</h5>
                            <p>Enjoy hassle-free processing alongwith quick 2-min onboarding</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>
 

    </>
  );
}

export default WhyChoose;