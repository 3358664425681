import React from "react";
import "./Modal.css";
const CirtificateModal = ({closeCirtificateModal,item }) => {
  const { REACT_APP_APIURL } = process.env;
  return (
    <>
      <div className="modalBackground col30">
        <div className="modalContainer">
        <div className="d-flex justify-content-between border-bottom pb-2">
          <div><h5>View</h5></div>
          <div className="ml-auto">
            <button className="btn btn-danger btn-sm"
              onClick={() => {
                closeCirtificateModal(false);
              }}
            >
              X
            </button>
          </div>
        </div>
          <div className="text-center">
              <img className="rounded-circle-half mx-width-300" src={`${REACT_APP_APIURL}/storage/idcard/${item.idcard}`} alt='IdCard' />
            </div>  
        </div>
      </div>
  </>

  )
}

export default CirtificateModal;