import React from 'react';
import Footer from '../Footer';
import mentorImg from './../../../images/mentor.png'
import { FaSquareFacebook,FaTwitter,FaInstagram } from "react-icons/fa6";
import HowItWork from '../sections/HowItWork';
import Testimonial from '../testimonial/Testimonial';
import rightMark from './../../../images/gift/rightMark40.jpg';
import Slider from "react-slick";
import testiUser from './../../../images/gift/testUser.webp'
import MentorReview from '../reviews/MentorReview';
import StillConfuse from '../sections/StillConfuse';
import { useState,useEffect } from 'react'
import ApplyForm from '../../modal/ApplyForm';
const BecomeMentor = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay:true,
        slidesToScroll: 1
      };
const [applyFormModal, setApplyFormModal] = useState(false);
const applyNowFun=()=>{
    setApplyFormModal(true)
}
useEffect(() => {
window.scrollTo(0, 0)
}, [])
  return(
  <>
  {applyFormModal && <ApplyForm setApplyFormModal={setApplyFormModal}  />}
  <section className="loan-bg pb-5 pt-5 position-relative loan-apply-banner secWrap">

<div className="container">

  <div className="row">
    <div className="col-lg-6">
      <h2 className="f_size_40 wt-8 text-dark l_height45  mb_50 loan-heading loan-apply-new n-text-transform">
      Become a Mentor & Guide Your Junior or  <br />
        <span className="c_dark">Fello Students.</span>
      </h2>
      <p className="ft-17 text-dark">Make the aspairents journey easier and simpler through your experiences and insights  while earning handsome payouts.</p>
      <button className="btn btn-cd btn-lg mt-2" onClick={applyNowFun}>Apply Now</button> 
    </div>
    <div className="col-lg-6  ml-auto my-auto">
    <img className="w-100" src={mentorImg} />
    </div>
  </div>
</div>
</section>
    <section className='secWrap bg-cd-light'>
<div  className="container-xxl py-5">
        <div  className="container">
            <div  className="row g-4">
            <div className='col-sm-12 mb-4 text-center'>
            <h3 className="mb-0 sec-title-txt text-center">Why Should You Be CD Mentors</h3>
               <p class="text-center mt-3 wt-6">Can you bring people’s career dreams to reality? “Yes. 100%” If this is what your heart responded , you have a super power. <br />CD platform wil do every bit to help you realise and exercise this power.</p>
            </div>
                <div  className="col-lg-6 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                        <img className="giftImg" src={rightMark} />
                            <h5  className="mb-3 mt-2">Build Your Personal Brand</h5>
                            <p>It looks great on your cv, Boosting employability with skills such as leadership, time management, problem solving ,
                             communication, event management, activity listening and team work.</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-6 col-sm-6 wow fadeInUp" >
                    <div  className="service-item text-left">
                        <div  className="p-4">
                        <img className="giftImg" src={rightMark} />
                            <h5  className="mb-3 mt-2">Give back to society</h5>
                            <p>It is an opportunity to give something back by supporting first-year students with their transition to college/ university.</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-6 col-sm-6 wow fadeInUp" >
                    <div  className="service-item text-left">
                        <div  className="p-4">
                        <img className="giftImg" src={rightMark} />
                            <h5  className="mb-3 mt-2">Be a Role model and Inspire</h5>
                            <p>It is an excellent opportunity to build connection with a lot of future learners</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-6 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                        <img className="giftImg" src={rightMark} />
                            <h5  className="mb-3 mt-2">Mentor & Earn</h5>
                            <p>It is a paid opportunity with casual hour that fit around your studies,
                             social life and other commitments. Help students and earn money at same time.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

 <section className='secWrap bg-cd'>
<div  className="container-xxl py-5">
        <div  className="container">
            <div  className="row g-4">
            <div className='col-sm-12'><h3 className="mb-4 text-white sepLine">How can you be a Mentor?</h3></div>
            <div  className="col-lg-3 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-center">
                        <div  className="p-4">
                            <div className="ser-icon text-white">1</div>
                         <p>Apply & Fill the form <br />&nbsp;</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-3 col-sm-6 wow fadeInUp" >
                    <div  className="service-item text-center">
                        <div  className="p-4">
                        <div className="ser-icon text-white">2</div>
                            <p>Share all your relevant
                               details including your social handles!</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-3 col-sm-6 wow fadeInUp" >
                    <div  className="service-item text-center">
                        <div  className="p-4">
                        <div className="ser-icon text-white">3</div>
                            <p>Share your profile link &tell the world!</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-3 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-center">
                        <div  className="p-4">
                        <div className="ser-icon text-white">4</div>
                            <p>Mentor, monetise,
                              and create a difference!</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>
<StillConfuse />
<MentorReview />
 <Footer />
  </>
        );
    }

    export default BecomeMentor;