import React from 'react';
import Footer from '../Footer';

const PrivatePolicy = () => {

    return (
        <>

            <section className="about-section secWrap">
                <div className="container">
                    <div className="text-container">
                        <h3>Private Data We Receive And Collect</h3>
                        <p>Corso also automatically collects and receives certain information from your computer or mobile device, including the activities you perform on our Website, the Platforms, and the Applications, the type of hardware and software you are using (for example, your operating system or browser), and information obtained from cookies. For example, each time you visit the Website or otherwise use the Services, we automatically collect your IP address, browser and device type, access times, the web page from which you came, the regions from which you navigate the web page, and the web page(s) you access (as applicable). There is more to this section and we want.</p>
                        <p>When you first register for a Corso account, and when you use the Services, we collect some <a href="#your-link">Personal Information</a> about you such as:</p>
                        <div className="row">
                            <div className="col-md-6">
                                <ul className="list-unstyled li-space-lg">
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body">The geographic area where you use your computer and other mobile devices should be the same one software provider</div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body">Your full name, username, and email address and other contact details should be provided in the contact forms</div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body">A unique Corso user ID (an alphanumeric string) which is assigned to you upon registration should always be at front</div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body">Every system is backuped regularly and it will not fail</div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body">Your IP Address and, when applicable, timestamp related to your consent and confirmation of consent but please make</div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body">Other information submitted by you or your organizational representatives via various methods and practiced techniques</div>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-md-6">
                                <ul className="list-unstyled li-space-lg">
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body">Your billing address and any necessary other information to complete any financial transaction, and when making purchases through the Services, we may also collect your credit card or PayPal information or any other sensitive data</div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body">User generated content (such as messages, posts, comments, pages, profiles, images, feeds or communications exchanged on the Supported Platforms that can be used)</div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body">Images or other files that you may publish via our Services</div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body">Information (such as messages, posts, comments, pages, profiles, images) we may receive relating to communications you send us, such as queries or comments concerning</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer />
        </>
    );
}

export default PrivatePolicy;