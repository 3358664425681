import React from 'react';
import Slider from "react-slick";
import sunilImg from './../../../images/testimonial/sunil_yadav_tiis1.jpeg';
import nituImg from './../../../images/testimonial/neetu_college1.jpeg';
import harshitaImg from './../../../images/testimonial/harshita_empi.jpeg';
const MentorReview = ({ RevHead }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay:true,
    slidesToScroll: 1
  };
  return (
    <>
    <section className='secWrap mentoRev'>
          <div className="container">
          <div className="text-center sec-title ft-37 wt-7">Mentor Reviews</div>
              <Slider {...settings}>
              {/* Slide 1 */}
              <div>
                  <div className='row'>
                      <div className="col-lg-4 col-sm-12 wow fadeInUp">
                        <div className="service-item text-left mentorItem">
                          <div className="d-flex">
                            <div className="mentorIcon"><img width={50 + 'px'} height={50 + 'px'} src={sunilImg} /></div>
                            <h3 className="mb-3 mt-2">Sunil Yadav
                              <span>TISS, Mumbai</span>
                            </h3>
                          </div>
                          <p>College Dwar has provided me with an opportunity to mentor students from various walks of life, not only helping me become a better mentor and leader but also enriching my experiences and learnings.
                           The program is very structured and is useful for people at both ends of the network. Great job!</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 wow fadeInUp">
                        <div className="service-item text-left mentorItem">
                          <div className="d-flex">
                            <div className="mentorIcon"><img width={50 + 'px'} height={50 + 'px'} src={nituImg} /></div>
                            <h3 className="mb-3 mt-2">Neetu Sharma
                              <span>GIBS, Bangalore</span>
                            </h3>
                          </div>
                          <p>I love the team support and reach that College Dwar provides to mentors, I feel I am more of a collaborator than a freelancer on this platform.</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 wow fadeInUp">
                        <div className="service-item text-left mentorItem">
                          <div className="d-flex">
                            <div className="mentorIcon"><img width={50 + 'px'} height={50 + 'px'} src={harshitaImg} /></div>
                            <h3 className="mb-3 mt-2">Harshita Kaushik
                              <span>EMPI, Delhi</span>
                            </h3>
                          </div>
                          <p>The mentorship platform on College Dwar is an amazing initiative that fulfilled my desire to share my learnings, and experiences with future professionals traversing through the path. I had several queries at the stages of my career which I faced trouble in finding solutions.
                           I am glad that I am able to help them through College Dwar now.</p>
                          </div>
                        </div>
                    </div>
                </div>
                {/* End of Slide 1 */}
               
              </Slider>
          </div>
      </section>
    </>
  );
}

export default MentorReview;