import React from 'react'
import BreadCrumb from './BreadCrumb';
import FaqCard from './faq/FaqCard';
import Footer from './Footer';
export default function Faq() {
	
	  return (
		<>
		
		<div className='container'>
		<div className="text-center sec-title ft-37 wt-7 pt-4">FAQs</div>
		<FaqCard />
		</div>
		
		</>
	  )
	  
	}