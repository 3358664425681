import React from 'react'
import icon1 from "./../../../images/other/supp.jpg";
import icon2 from "./../../../images/other/placemrnt.jpg";
import icon3 from "./../../../images/other/adbenifit.jpg";

export default function SectionChoose() {
  return (
    <>
      <section className="features-1 secWrap">
      <div className="container">
        <div className="row">
		<div className='col-sm-12 mb-5 text-center'>
               <h3 className="mb-0 sec-title-txt">Why Choose <span className="text-cd">College Dwar</span></h3>
			   <p class="text-center mt-3 wt-6">Have you ever thought that why you never get calls for what food you want to eat, which brand of clothes to wear, which phone to buy etc. You never get calls but you get a lot of unwanted calls for college suggestions. Who are these people? And why are they calling?
         Don't fall into the trap of these scammers who will land you in the wrong college.
         </p>
       
       <p className='ft-20 wt-7 text-cd'>Say goodbye to faceless call centres/ spammers/ agents</p>
            </div>
          <div className="col-12 col-lg-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
            <div className="box-feature">
			 <img className="img-fluid" src={icon1} alt='icon1Img' />
              <h3 className="mb-3">Unbiased Experts</h3>
              <p>
			  Connect Directly with college admissions representatives and current students.
			   Learn about all the exicsting opportunities open for you, right from the source. <br /> <br />
              </p>
              <p><a href="#" className="flex-shrink-0 btn btn-sm btn-primary px-3 tp-ex-btn">Learn More</a></p>
            </div>
          </div>
          <div className="col-12 col-lg-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
            <div className="box-feature">
            <img className="img-fluid" src={icon2} alt='icon1Img' />
              <h3 className="mb-3">Guaranteed Placement</h3>
              <p>
			  Get min 5 Lpa and avg 7-9 Lpa placement or get your college tution fees back. 
			  No question asked. <br /> <br /> <br /> <br />
              </p>
              <p><a href="#" className="flex-shrink-0 btn btn-sm btn-primary px-3 tp-ex-btn">Learn More</a></p>
            </div>
          </div>
          
          <div className="col-12 col-lg-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="600">
            <div className="box-feature">
			<img className="img-fluid" src={icon3} alt='icon1Img' />
              <h3 className="mb-3">Post Admission Benefits</h3>
              <p>
			  3 months MBA Booster Bootcamp ( worth 25k), Workshops, Welcome gifts (worth 10k),
			   Healthcare Support ( 24*7 Expert Doctors, medicine and tests ) ,
			    1:1 expert mentors from IIT/IIM , Google, Microsoft, Meta etc alumni.
              </p>
              <p><a href="#" className="flex-shrink-0 btn btn-sm btn-primary px-3 tp-ex-btn">Learn More</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}
