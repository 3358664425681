import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { Link,Routes,Route } from 'react-router-dom';
import Error from '../Error';
import Login from '../Login';
import Register from '../Register';
import Home from './../frontend/Home'
import FindExperts from '../frontend/FindExperts';
import ExpertDetail from '../frontend/home/ExpertDetail';
import Faq from '../frontend/Faq';
import Loan from '../frontend/other/Loan';
import Refer from '../frontend/other/Refer';
import PostAdmission from '../frontend/other/PostAdmission';
import BecomeMentor from '../frontend/other/BecomeMentor';
import Mentors from '../frontend/home/Mentors';
import OnlineMba from '../frontend/other/OnlineMba';
import CollegeUniv from '../frontend/other/CollegeUniv';
import ChanelPartner from '../frontend/other/ChanelPartner';
import CollegeFair from '../frontend/other/CollegeFair';
import HowItWork from '../frontend/other/HowItWork';
import About from '../frontend/other/About';
import PrivatePolicy from '../frontend/other/PrivatePolicy';
import Term from '../frontend/other/Term';
import Contact from '../frontend/other/Contact';
import logo from "./../../images/logo.jpg";
export default function Guest() {
  return (
    <div>
<Navbar collapseOnSelect expand="lg" className="bg-white shadow-sm " sticky="top">
      <Container>
        <Navbar.Brand><Link className="nav-link" to={"/"}>
          <img className='logo' src={logo} />
        </Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Link className="nav-link" to={"/about-us"}>About Us</Link>
            <Link className="nav-link" to={"/online/admission"}>Online MBA</Link>
            <Link className="nav-link" to={"/postadmission"}>Post Admission Benefit</Link>
            {/* <Link className="nav-link" to={"/mentors/:type"}>Find Mentors</Link> */}
            <Link className="nav-link" to={"/refer"}> Refer & Earn</Link>
            <Link className="nav-link" to={"/become/mentor"}>Become a Mentor</Link>
            {/* <Link className="nav-link" to={"/login"}>Login</Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
            {/* <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
                <ul className="navbar-nav">
                    <li>

                    <Link to={"#"} className='navbar-brand'>Collage Dwar</Link>

                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Login</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/register">Register</Link>
                    </li>

                </ul>

            </nav> */}
            
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/experts/:type?/:courseId?/:cityId?" element={<FindExperts />} />
                    <Route path="/expertsInfo/:type/:typeId/:courseId" element={<ExpertDetail />} />
                    <Route path="/faqs" element={<Faq />} />
                    <Route path="/loan" element={<Loan />} />
                    <Route path="/Refer" element={<Refer />} />
                    <Route path="/postadmission" element={<PostAdmission />} />
                    <Route path="/become/mentor" element={<BecomeMentor />} />
                    <Route path="/mentors/:type" element={<Mentors />} />
                    <Route path="/online/admission" element={<OnlineMba />} />
                    <Route path="/college/univ" element={<CollegeUniv />} />
                    <Route path="/chanel/partner" element={<ChanelPartner />} />
                    <Route path="/college/fair" element={<CollegeFair />} />
                    <Route path="/how-it-work" element={<HowItWork />} />
                    <Route path="/about-us" element={<About />} />
                    <Route path="/private-policy" element={<PrivatePolicy />} />
                    <Route path="/term" element={<Term />} />
                    <Route path="/contact-us" element={<Contact />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="*" element={<Error />} />
                </Routes>
            
      
    </div>
  )
}
