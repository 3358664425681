import React from 'react';
import Footer from '../Footer';
import postAdImg from './../../../images/postad.jpeg'

import StillConfuse from '../sections/StillConfuse';
import ApplyForm from '../../modal/ApplyForm';
import {useEffect } from 'react'
const PostAdmission = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])   
  return (
    <>
    
<section className="loan-bg pb-5 pt-5 position-relative loan-apply-banner secWrap">

<div className="container">

  <div className="row">
    <div className="col-lg-6">
      <h2 className="f_size_40 wt-8 text-dark l_height45  mb_50 loan-heading loan-apply-new">
      Boost your Career & Build Your Goals with College Dwar   <br />
        <span className="c_dark">Post Admission Support</span>
      </h2>
      <p className="ft-17 text-dark">Get a chance to placed in fortune 500 Companies like Google,BCG,MCKinsey,Bain&CO etc.</p>
     
    </div>
    <div className="col-lg-6  ml-auto my-auto">
    <img className="w-100" src={postAdImg} />
    </div>
  </div>
</div>
</section>
     
      <section  className="pricing-section spad secWrap bg-cd-light">
        <div  className="container">
            <div  className="row">
                <div  className="col-lg-12 col-md-12 mb-4">
                    <div  className="section-title normal-title text-center">
                        <h3 className="mb-0 sec-title-txt text-center">Advantage for Post Benefit</h3>
               <p class="text-center mt-3 wt-6">College Dwar has a team of 2500+ experts who are there to assist you and give you the right guidance
              <br /> for your successful career ahead.</p>
                    </div>
                </div>
              
            </div>
            <div  className="row monthly__plans active mt-4">
            <div  className="col-lg-4 col-md-6 col-sm-6">
                    <div  className="pricing__item">
                        <h4>Internship & Placement Support</h4>
                        <ul>
                        <li>Advance webinars, Networking Sessions with Decision Makers.</li>
                        <li>Live project and sessions, placement assistance..</li>
                    </ul>
                       
                        
                    </div>
                </div>
                <div  className="col-lg-4 col-md-6 col-sm-6">
                    <div  className="pricing__item">
                    <h4>Gift</h4>
                       
                       <ul>
                        <li>Get welcome kit bag after admission.</li>
                        <li>Smartwatch, Earbuds, notebooks, coffee mug , Tshirt etc.</li>
                    </ul>
                       
                    </div>
                </div>
                <div  className="col-lg-4 col-md-6 col-sm-6">
                    <div  className="pricing__item">
                    <h4>Health Care Support</h4>
                       <ul>
                        <li>Get 24*7 consultation from experts doctors.</li>
                        <li>Free health checkups, medicines, Blood tests etc.</li>
                    </ul>
                    </div>
                </div>
                <div  className="col-lg-4 col-md-6 col-sm-6">
                    <div  className="pricing__item">
                        <h4>Exclusive CD Telegram/WhatsApp Community</h4>
                        <ul>
                        <li>An Inter-College community of college Dwar students.</li>
                        <li>A selected group of College Dwar Students persuing the same course as you
                        However from various colleges both in India and abrod We wish you.</li>
                    </ul>
                    
                       
                        
                    </div>
                </div>
                <div  className="col-lg-4 col-md-6 col-sm-6">
                    <div  className="pricing__item">
                    <h4>Mentorship</h4>
                    <ul>
                        <li>Get 1-1 mentorship from IIT/ IIM alumni.</li>
                        <li>Industry experts from Google, Facebook, Asian Paints, ITC etc</li>
                    </ul>
                     
                    </div>
                </div>
                <div  className="col-lg-4 col-md-6 col-sm-6">
                    <div  className="pricing__item">
                    <h4>Industry Networking & Bootcamps</h4>
                       <p>
                       <ul>
                            <li>Fun-Packed informative and career building workshops session by industry 
                                professional and professors.</li>
                            <li>Group activities with your peers and aluminis.</li>
                            <li>Live sessions by experts on various industry topics.</li>
                            <li>On-on-One discussion and feedback sessions with industry's mentors.</li>
                       </ul>
                       
                       </p>
                       
                       
                    </div>
                </div>
            </div>
            
        </div>
    
    </section>
   <StillConfuse />
  
      <Footer />
    </>
  );
}

export default PostAdmission;