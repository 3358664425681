import React from 'react'

import { useState,useEffect } from 'react'
import http from '../../../http'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
export default function AddCourse() {

   const navigate = useNavigate();

   const[input,setInput] = useState({});
   const [btnLoading, setBtnLoading] = useState(false);
   const[messages,setMessages] = useState();

   useEffect(() => {

    document.title = "Add Course";

    }, [])

   const handleChange = (e) => {
        
        const name=e.target.name;
        const value=e.target.value;
        setInput(values=>({...values,[name]:value}));

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setBtnLoading(true);
        http.post('/add-course/process',input)
           .then(response => {
                setMessages("Course added successfully");
                setBtnLoading(false);
            toast.success("Course has been successfully created !")
            })
            .catch(e => {
                setBtnLoading(false);
                console.log("Not added");
            });
    }


  return (
    <div>
 
        <br></br>
        <ToastContainer  />
        {messages && 

            <div className="alert alert-primary" role="alert">
                {messages}
            </div>

        }

        <div style={{ border: "2px solid black",padding:"50px" }}>

        <h2>Add Course</h2>

        <br></br>
            <div className="form-group col-lg-6 mb-2">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" name="name" value={input.name || ''} onChange={handleChange} placeholder="Enter name" />
            </div>
            
            <br></br>

            {
            btnLoading ? <>
            <button class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
          </> : <>
          <button type="submit" onClick={handleSubmit} className="btn btn-primary form-submit">Submit</button>
          </>
          }


        </div>

    </div>
  )
}
