import React from "react";
import { useState } from 'react'
import http from '../../http'
import "./Modal.css";
import usr from './../../images/usr1.jpg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
const ApplyForm = ({ setApplyFormModal }) => {
  const [input, setInput] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

const handleChange=(e)=>{
  const { name, value } = e.target;
  setInput(values => ({ ...values, [name]: value }));
}
const submitFinder = async (e) => {
  e.preventDefault();
  setBtnLoading(false);
  toast.success("Thank you for submitting ! We will revert back you soon")
  setTimeout(function () { setApplyFormModal(false);}, 3000);
  
 
}
  return (
    <>
<ToastContainer  />
      <div className="modalBackground col30">
        <div className="modalContainer">
        
          <form>
            <div className="mt-2 mb-2 text-center">
             <p className="popup-heading">Just a bit more about you!</p>
             <p className="popup-subheading">Please share your details so that our advisor can connect with you.</p>
              <div className="col-lg-12 mb-4 text-start">
              <div className="mb-2">
                  <div className="mb-1"><label>Name</label></div>
                  <input type="text" name="name" value={input.name || ''} onChange={handleChange} className="form-control" />
              </div>
              <div className="mb-2">
                  <div className="mb-1"><label>Email</label></div>
                  <input type="email" name="email" value={input.email || ''} onChange={handleChange} className="form-control" />
              </div>
              <div className="mb-2">
                  <div className="mb-1"><label>Mobile No</label></div>
                  <input type="number" name="phone" value={input.phone || ''} onChange={handleChange} className="form-control" />
              </div>
              <div className="mb-2">
                  <div className="mb-1"><label>Message</label></div>
                  <textarea rows={4} cols={52} name="message" value={input.message || ''} onChange={handleChange} className="form-control" />
              </div>
              <div className="mt-4 text-end">
              {
                btnLoading ? <>
                  <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>
                </> : <>
                  <button type="submit" className="btn btn-primary" onClick={submitFinder}>Submit</button>
                  <button type="submit" className="btn btn-light ms-3" onClick={() => {
                setApplyFormModal(false);
              }} >Cancel</button>
                </>
              }
              </div>
                    

              
              </div>
            </div>

            
          </form>
        </div>
      </div>



    </>

  )
}

export default ApplyForm;