import React from 'react'
import { Link } from 'react-router-dom';
import logo from "./../../images/logo.jpg";
import fb from "./../../images/social/fb.jpg";
import linkedin from "./../../images/social/linkedin.jpg";
import insta from "./../../images/social/insta.jpg";
import twitter from "./../../images/social/twitter.jpg";
import { FaFacebook, FaSquareFacebook, FaTwitter, FaInstagram } from "react-icons/fa6";
export default function Footer() {
	return (
		<>
			<footer className="footer">
				{/* <div className="footer_background" ></div> */}
				<div className="container">
					<div className="row footer_row">
						<div className="col">
							<div className="footer_content">
								<div className="row">

									<div className="col-lg-4 footer_col">

										<div className="footer_section footer_about">
											<div className="footer_logo_container">

												<div className="footer_logo_text">
												<Link className="text-white underline pointer" to={"/"}><img src={logo} className='ft-logo' /></Link>
												</div>

											</div>
											<div className="footer_about_text">
												<p>
												Looking for colleges ? Apply in 500+ colleges across india and get your admission done in 48 hrs
												</p>
											</div>
											<div className="footer_social">
												<ul className='m-0 p-0'>

													<li><img src={fb} className='ft-logo' /></li>
													<li><img src={linkedin} className='ft-logo' /></li>
													<li><img src={insta} className='ft-logo' /></li>
													<li><img src={twitter} className='ft-logo' /></li>

												</ul>
											</div>
										</div>

									</div>


									<div className="col-lg-8">
										<div className="row">
											<div className="col-lg-4 footer_col">


												<div className="footer_section footer_links">
													<div className="footer_title">Company</div>
													<div className="footer_links_container">
														<ul className='p-0'>
															<li><Link className="text-white underline pointer" to={"/about-us"}>About Us</Link></li>
															<li><Link className="text-white underline pointer" to={"/"}>Careers</Link></li>
															<li><Link className="text-white underline pointer" to={"/contact-us"}>Contact Us</Link></li>







														</ul>
													</div>
												</div>

											</div>

											<div className="col-lg-4 footer_col">


												<div className="footer_section footer_links">
													<div className="footer_title">Student</div>
													<div className="footer_links_container">
													<ul className='p-0'>
															<li><Link className="text-white underline pointer" to={"/loan"}>Loan</Link></li>
															<li><Link className="text-white underline pointer" to={"/refer"}>Refer & Earn</Link></li>
															<li><Link className="text-white underline pointer" to={"/postadmission"}>Post Admission Benifit</Link></li>
															<li><Link className="text-white underline pointer" to={"/become/mentor"}>Become Mentor</Link></li>
															{/* <li><Link className="text-white underline pointer" to={"/mentors/all"}>Find Mentors</Link></li> */}
															<li><Link className="text-white underline pointer" to={"/online/admission"}>Online MBA</Link></li>
														</ul>
													</div>
												</div>

											</div>
											<div className="col-lg-4 footer_col">
												<div className="footer_section footer_links">
													<div className="footer_title">Resources</div>
													<div className="footer_links_container">
													<ul className='p-0'>
															<li><Link className="text-white underline pointer" to={"/college/univ"}>College & University</Link></li>
															<li><Link className="text-white underline pointer" to={"/chanel/partner"}>Chanel Partner</Link></li>
															<li><Link className="text-white underline pointer" to={"/college/fair"}>College Fair</Link></li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row copyright_row">
						<div className="col">
							<div className="copyright d-flex flex-lg-row flex-column align-items-center justify-content-between">
								<div className="cr_text">
									Copyright &copy; All rights reserved
								</div>
								<div className="ml-lg-auto cr_links">
									<ul className="cr_list">

										<li><Link className="text-white underline pointer" to={"/term"}>Terms & Condition</Link></li>
										<li><Link className="text-white underline pointer" to={"/private-policy"}>Privacy Policy</Link></li>
										
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}
